import { Action, AnyAction } from 'redux';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { Container } from 'typedi';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/catch';

import { SET_LOAD_DETAILS, SetLoadDetailsAction } from '../../load-details.actions';
import { DocumentRepository } from 'app/repositories/document.repository';
import { DocumentCategory } from 'shared/enums/document-category.enum';
import { FETCH_DOCUMENTS, FetchDocumentsAction, fetchDocumentsError, fetchDocumentsSuccess } from './documents.actions';
import { User } from 'shared/models/user.model';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';

export interface EpicDependencies {
  repo: DocumentRepository;
}
type DocumentsEpic<OutputAction extends Action = AnyAction> = NavCarrierEpic<OutputAction, EpicDependencies>;

export const selectDocumentCategories = (user: User) => {
  let categories: DocumentCategory[] = [DocumentCategory.General];

  if (user.roles.AR) {
    categories = [...categories, DocumentCategory.AccountsReceivable];
  }
  return categories;
};

export const loadDocumentsEpic: DocumentsEpic = (action$, state$, {repo}) =>
  action$.ofType<FetchDocumentsAction | SetLoadDetailsAction>(SET_LOAD_DETAILS, FETCH_DOCUMENTS)
    .filter(action => Boolean(action.load))
    .switchMap(action =>
      repo.getDocuments(action.load, selectDocumentCategories(state$.value.auth.user))
        .map(fetchDocumentsSuccess)
        .catch(e => ActionsObservable.of(fetchDocumentsError(e)))
    )
    .catch(e => ActionsObservable.of(fetchDocumentsError(e)));

export const documentsEpic = (action$, store) => combineEpics(
  loadDocumentsEpic
)(action$, store, {repo: Container.get(DocumentRepository)});

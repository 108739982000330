import { useMemo } from 'react';
import { mapToAdobeSearchCriteria, mapToAdobeSearchResults } from '@features/analytics/utils/adobe-search-events-data-mapper';

export const useAdobeSearchEventsDataMapper = ({ searchCriteria, searchResults }: SearchEventsData): MappedAdobeSearchData => {
  const adobeSearchCriteria = useMemo(() => mapToAdobeSearchCriteria(searchCriteria), [searchCriteria]);
  const adobeSearchResults = useMemo(() => mapToAdobeSearchResults(searchResults), [searchResults]);

  return {
    adobeSearchCriteria,
    adobeSearchResults,
  };
};

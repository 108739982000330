import { Locks } from './constants';
/**
 * Some browsers have a tab freezing or sleeping feature
 * to reduce computer resource usage for inactive tabs.
 * To prevent this, lets use a lock to ensure our tab stays active.
 * More on navigator.locks [LockManager](https://developer.mozilla.org/en-US/docs/Web/API/LockManager)
 * @see https://developer.chrome.com/blog/timer-throttling-in-chrome-88/
 */

var release, release_reject;
const p = new Promise((res, rej) => {
  release = res;
  release_reject = rej;
});

// Only available in secure contexts
const lock: LockManager = window.navigator.locks;


/*
  * Request our concurrency lock here.
  *
  * We call it and leave it open, ensuring
  *  that browsers won't look at this tab as 'sleepable'.
  *
  * `Locks.LockId` is a constant at 'app/core/globals/constants.ts', this
  *  is used as our unique concurrency key, this way if this scales out, we can all communicate
  *  using the same Id
  */


lock.request(Locks.LockId,  {mode: "shared"}, () => {
    // Lock is acquired.
    // Now lock will be held until either release() or release_reject() is called.
    return p;
  }).then(res => {}).catch(err => {})


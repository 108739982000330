import { useEffect } from 'react';
import { disableOktaForUser, enableOktaForUser } from 'utils/auth';

/**
 * This hook enables a global query param method
 *  to enabled/ disable OKTA.
 *
 * `?cookie=dump`
 *
 * `?cookie=set`
 */
const useSetCookieOnQueryParam = () => {
 const { search } = window?.location;

 useEffect(() => {
  const option = new URLSearchParams(search).get('cookie');

  if (option === 'dump') {
   disableOktaForUser();
  } else if (option === 'set') {
   enableOktaForUser();
  }
 }, [search]);
};

export default useSetCookieOnQueryParam;

import React from "react";
import { useAnalyticsTrack } from "@chr/react-analytics";
import { EVENT_TYPES } from "../constants";
import { generateEventSequence } from "../utils/event-sequence-generator";
import { useAnalyticsEventMetaData } from "../hooks/use-analytics-event-metadata.hook";
import { useAdobeSearchEventsDataMapper } from "../hooks/use-adobe-search-events-data-mapper.hook";
import { useSearchEvents } from "../redux/analytics-v2.selectors";

export const RecommendedLoadsEventComponent = () => {
  const track = useAnalyticsTrack();
  const eventMetaData = useAnalyticsEventMetaData();
  const { recommendedLoads, searchCorrelationId, pageSize, pageNumber, totalVisibleResults, subsetOfResultsCount } = useSearchEvents();
  const { adobeSearchResults: loadList } = useAdobeSearchEventsDataMapper({ searchResults: recommendedLoads });

  const eventData = React.useMemo(() => {
    const { timeGMT, sequenceNumber: sessionEventSequenceNumber } = generateEventSequence();

    const data: KeyValuePair = {
      ...eventMetaData,
      searchCorrelationId,
      resultSet: {
        loadList,
        pageSize,
        pageNumber,
        totalVisibleResults,
        subsetOfResultsCount
      },
      sequence: {
        isInfinityWorkflow: true,
        timeGMT,
        sessionEventSequenceNumber,
      }
    };

    return data;
  }, [
    pageSize,
    pageNumber,
    totalVisibleResults,
    subsetOfResultsCount,
    recommendedLoads,
    searchCorrelationId,
    eventMetaData,
  ]);

  React.useEffect(() => {
    if (recommendedLoads) {
      track({ event: EVENT_TYPES.RECOMMENDED_LOAD_LIST_VIEWED, ...eventData });
    }
  }, [recommendedLoads, track]);

  return null;
};

import { useEffect, useMemo, useState } from "react";
import { CustomContentCardData } from "@features/braze/types";
import { NOTIFICATION_FEED } from "@features/braze/notification-center-feed";

export const useReduceBrazeCard = (contentCards: CustomContentCardData[] | undefined) => {

    const [validCards, setValidCards] = useState<CustomContentCardData[]>([]);
    const [countCards, setCountCards] = useState<number>(null);

    const validCardList = useMemo(() => {
        return (contentCards ?? []).reduce((cards: CustomContentCardData[], each: CustomContentCardData) => {
            const isDuplicate = cards.some(({ extras }) => !!extras?.card_id && extras?.card_id === each.extras?.card_id);
            const isValidCard = !isDuplicate && each.extras?.page_name === NOTIFICATION_FEED && !each.clicked && !each.viewed;
            if (isValidCard) {
                return [...cards, each];
            }
            return cards;
        }, []);
    }, [contentCards]);

    useEffect(() => {
        setValidCards(validCardList);
        setCountCards(validCards.length);
    }, [validCardList, validCards.length]);


    return { validCards, countCards };
}
import { Action, AnyAction } from 'redux';
import { combineEpics, ActionsObservable } from 'redux-observable';
import { Container } from 'typedi';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';

import * as a from './container-update.actions';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { GlobalShipmentsRepository } from 'app/repositories/global-shipments.repository';

export interface EpicDependencies {
  repo: GlobalShipmentsRepository;
}

type ContainerUpdateEpic<OutputAction extends Action = AnyAction> = NavCarrierEpic<OutputAction, EpicDependencies>;

export const fetchContainersEpic: ContainerUpdateEpic = (action$, state$, {repo}) => {
  return action$.ofType<a.FetchContainersAction>(a.FETCH_CONTAINERS)
    .switchMap(action =>
      repo.getShippingContainers(action.loadNumber)
        .map(a.fetchContainersSuccess)
        .catch(err => ActionsObservable.of(a.fetchContainerUpdateFailure(err)))
    );
};

export const fetchContainerStatusesEpic: ContainerUpdateEpic = (action$, state$, {repo}) => {
  return action$.ofType<a.FetchContainersAction>(a.FETCH_CONTAINERS)
    .switchMap(action =>
      repo.getContainerStatuses(action.loadNumber, action.bookType)
        .map(a.fetchContainerStatusesSuccess)
        .catch(err => ActionsObservable.of(a.fetchContainerUpdateFailure(err)))
    );
};

export const fetchEquipmentInfoEpic: ContainerUpdateEpic = (action$, state$, {repo}) => {
  return action$.ofType<a.FetchContainersAction>(a.FETCH_CONTAINERS)
    .switchMap(action =>
      repo.getEquipmentInfo(action.loadNumber, action.bookSeqNum)
        .map(a.fetchEquipmentInfoSuccess)
        .catch(err => ActionsObservable.of(a.fetchContainerUpdateFailure(err)))
    );
};

export const containerUpdateEpic = (action$, store) => combineEpics(
  fetchContainersEpic,
  fetchContainerStatusesEpic,
  fetchEquipmentInfoEpic
)(action$, store, {repo: Container.get(GlobalShipmentsRepository)});

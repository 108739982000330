import moment from 'moment';
import { FunctionComponent } from 'react';
import { useDismissal } from './hooks/use-dismissal.hook';
import { useSiteConfiguration } from 'app/hooks/store/use-site-configuration.hook';
import { Banner } from '@chr/eds-react';
import { useTranslate } from '@shared/components/translation/translation.component';

export const MaintenanceWindow: FunctionComponent = () => {
  const config = useSiteConfiguration();
  const [isDismissed, setDismissed] = useDismissal();
  const translate = useTranslate();


  if (!config || !config.maintenanceStartDate || isDismissed) {
    // configuration not loaded, no maintenance date set, or notification already dismissed
    return null;
  }
  if (config.maintenanceEndDate < moment().toDate() || config.maintenanceStartDate > moment().add(3, 'days').toDate()) {
    // maintenance window is greater than 3 days from now, or is already in the past.
    return null;
  }
  return (
    <div className="maintenance-window" style={{ paddingBottom: "10px" }}>
      <Banner
        title={translate('THIS_SITE_WILL_BE_UNAVAILABLE_ON_0_FROM_1_FOR_ROUT',
          [moment(config.maintenanceStartDate).format('dddd, MMMM D, YYYY h:mm A'),
            moment(config.maintenanceEndDate).format('dddd, MMMM D, YYYY h:mm A')])}
          severity="warning"
          actionOne={{
            buttonText: translate('DISMISS'),
            onClick: setDismissed,
          }}
      />
    </div>
  );
};

import { combineReducers } from 'redux';

import { ADD_DOCUMENT_FORM_KEY, addDocumentFormStructure } from './add-document/add-document.form';
import { createStructureReducer } from 'app/forms/structure/form-structure.reducers';
import { FETCH_DOCUMENTS_SUCCESS, FETCH_DOCUMENTS_ERROR } from './documents.actions';
import { FormGroupStructure } from 'app/forms/structure/form-group.structure';
import { LoadDocument } from 'shared/models/load-document.model';

export const documentsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_SUCCESS:
      return action.payload;
    case FETCH_DOCUMENTS_ERROR:
      return [];
    default:
      return state;
  }
};

export const errorReducer = (state = null, action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_ERROR:
      return action.error;
    case FETCH_DOCUMENTS_SUCCESS:
      return null;
    default:
      return state;
  }
};

export const documents = combineReducers({
  formState: createStructureReducer(addDocumentFormStructure(), ADD_DOCUMENT_FORM_KEY),
  documents: documentsReducer,
  error: errorReducer
});

export interface LoadDetailsDocumentsState {
  formState: FormGroupStructure;
  documents: LoadDocument[];
  error: Error;
}

import { useEffect } from 'react';

export const useMarkActiveOnNavigation = (subMenuItems, location, setIsActive, closeMenu) => {
  useEffect(() => {
    let innerIsActive = false;
    subMenuItems.map(item => {
      if (location.pathname.startsWith(item.pageUrl)) {
        innerIsActive = true;
      }
    });
    setIsActive(innerIsActive);
    closeMenu();

  }, [location, location.pathname]);
};

import { Route } from 'react-router';
import { AppRoute } from '@app/routesEnum';
import { SiteSection } from '@shared/components/analytics/site-section.component';
import { LazyInvitationLandingPage } from './index';

export default function AcceptInvitationRoutes() {
 return (
  <SiteSection name="Accept Invitation">
   <Route path={AppRoute.INVITATION} exact component={LazyInvitationLandingPage} />
  </SiteSection>
 );
}

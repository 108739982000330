import React from 'react';
import { useAnalyticsTrack } from '@chr/react-analytics';
import { EVENT_TYPES } from '@features/analytics/constants';
import { generateEventSequence } from '@features/analytics/utils/event-sequence-generator';
import { useAnalyticsEventMetaData } from '@features/analytics/hooks/use-analytics-event-metadata.hook';
import { useReloadsEvents } from '@features/analytics/redux/analytics-v2.selectors';
import { useAdobeSearchEventsDataMapper } from '@features/analytics/hooks/use-adobe-search-events-data-mapper.hook';

export const ReloadEventComponent = () => {
  const track = useAnalyticsTrack();
  const eventMetaData = useAnalyticsEventMetaData();
  const { reloads, primalLoadId, searchCorrelationId, callVariation } = useReloadsEvents();
  const { adobeSearchResults: reloadList } = useAdobeSearchEventsDataMapper({ searchResults: reloads });
  const eventData = React.useMemo(() => {
    const { timeGMT, sequenceNumber: sessionEventSequenceNumber } = generateEventSequence();

    const data: KeyValuePair = {
      ...eventMetaData,
      searchCorrelationId,
      reloadResultSet: {
        reloadList
      },
      reload: {
        callVariation: callVariation,
        primalLoadNumber: primalLoadId,
      },

      sequence: {
        isInfinityWorkflow: true,
        timeGMT,
        sessionEventSequenceNumber,
      }
    };

    return data;
  }, [eventMetaData, reloads, primalLoadId]);

  React.useEffect(() => {
    if (reloads) {
      track({ event: EVENT_TYPES.RELOADS_RESULTS, ...eventData });
    }
  }, [reloads, track]);

  return null;
};

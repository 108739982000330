import React from "react";
import { useAnalyticsTrack } from "@chr/react-analytics";
import { EVENT_TYPES } from "../constants";
import { useSearchEvents, useSortActionEvents } from "../redux/analytics-v2.selectors";
import { generateEventSequence } from "../utils/event-sequence-generator";
import { useAnalyticsEventMetaData } from '../hooks/use-analytics-event-metadata.hook';
import { useAdobeSearchEventsDataMapper } from '../hooks/use-adobe-search-events-data-mapper.hook';

export const SortActionEventComponent = () => {
  const track = useAnalyticsTrack();
  const eventMetaData = useAnalyticsEventMetaData();
  const { searchCriteria, searchCorrelationId, pageSize, pageNumber } = useSearchEvents();
  const sortEvent = useSortActionEvents();
  const { adobeSearchCriteria, adobeSearchResults: loadList } = useAdobeSearchEventsDataMapper({ searchCriteria, searchResults: sortEvent.sortedResults });

  const eventData = React.useMemo(() => {
    const { timeGMT, sequenceNumber: sessionEventSequenceNumber } = generateEventSequence();

    const data: KeyValuePair = {
      ...eventMetaData,
      sequence: {
        isInfinityWorkflow: true,
        timeGMT,
        sessionEventSequenceNumber,
      }
    };

    return data;
  }, [
    sortEvent,
    eventMetaData,
  ]);

  React.useEffect(() => {
    const { sortBy, sortOrder: sortType } = sortEvent;

    if (sortBy || sortType) {
      const data = {
        ...eventData,
        sortBy,
        sortType,
        searchCorrelationId,
        search: adobeSearchCriteria,
        resultSet: { loadList, pageSize, pageNumber }
      } as KeyValuePair;

      track({ event: EVENT_TYPES.SORT_ACTION, ...data });
    }
  }, [sortEvent, track]);

  return null;
}

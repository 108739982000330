import React from 'react';

import { Button } from 'shared/components/elements/elements.components';
import { Modal } from 'shared/components/modal/modal.component';
import { Translation, TranslationJSX } from 'shared/components/translation/translation.component';
import { useOnAcknowledgeTerms } from 'shared/components/terms-and-conditions/hooks/use-on-acknowledge-terms.hook';

import './terms-validation-modal.component.scss';

export const TermsValidationModal: React.FC = () => {
  const onAcknowledgeTerms = useOnAcknowledgeTerms();

  return (
    <Modal
      disableClose
      name="accept-terms"
      footer={<Button btnPrimary resource="I_ACKNOWLEDGE" onClick={onAcknowledgeTerms}/>}
    >
      <TranslationJSX resource="I_HAVE_READ_THE_TERMS_AND_AGREE_TO_THE_0_1_AND_2" replacements={
        [
          <a href="https://www.chrobinson.com/en/us/Terms-Of-Use/" target="_blank">
            <Translation resource="TERMS_OF_USE"/>
          </a>,
          <a href="https://www.chrobinson.com/en-us/privacy-notice/" target="_blank">
            <Translation resource="GLOBAL_PRIVACY_NOTICE"/>
          </a>,
          <a href="https://www.chrobinson.com/your-privacy-rights" target="_blank">
            <Translation resource="YOUR_PRIVACY_RIGHTS"/>
          </a>
        ]
      }/>
    </Modal>
  );
};

export const Container = {
 padding: '0 24px',
 display: 'flex',
 justifyContent: 'center',
};

export const Card = {
 display: 'flex',
 width: '656px',
 padding: '48px',
 flexDirection: 'column',
 alignItems: 'center',
 gap: '48px',
 backgroundColor: '#FFFFFF',
 borderRadius: '8px',
};

export const CardForm = {
 display: 'flex',
 flexDirection: 'column',
 gap: '24px',
 alignItems: 'center',
};

export const Header = {
 color: '#222',
 fontFamily: 'Open Sans',
 fontSize: '24px',
 fontStyle: 'normal',
 fontWeight: '400',
 lineHeight: '32px',
 textAlign: 'center',
};

export const Form = {
 width: '100%',
 textAlign: 'start',
};

export const CircularProgressIcon = {
 color: 'rgba(0, 0, 0, 0.28)',
};

export const FormButton = {
 height: '53px',
 width: '50%',
 border: '1px solid #AAAEB0',
};

export const SelectedButton = {
 ...FormButton,
 background: 'rgba(0, 113, 199, 0.08)',
 border: '2px solid #0071C7 !important',
};

export const PhoneNumberInputLabel = {
 gap: 0,
};

export const dialogContainer = {
 '& .MuiPaper-root': {
  width: '100%',
 },
 zIndex: '99999',
 backgroundColor: '#F6F7F8',
};

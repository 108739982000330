import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Pages } from 'pages/pages';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AvailableLoadSearchType } from 'shared/enums/available-loads/search-type.enum';
import { FindLoadsVer2Page } from 'pages/find-loads-ver2/index';

export const useFindLoadsComponent = () => {
	const isNorthAmerican = useSelector((state: any) => state.auth.user?.isNorthAmerican());
	const { findLoadsSearch } = useFlags()
	const sendLDEvents = process.env.NODE_ENV !== 'test';

	return useCallback((searchType: AvailableLoadSearchType) => {
		if (findLoadsSearch && isNorthAmerican) {
			return <FindLoadsVer2Page searchType={searchType} sendLDEvents={sendLDEvents} />;
		} else {
			return <Pages.FindLoads searchType={searchType} />;
		}
	}, [findLoadsSearch, isNorthAmerican, sendLDEvents]);
};
import classNames from 'classnames';
import { useCallback, useState } from 'react';

import { NotificationCenterFeed } from '@features/braze/notification-center-feed';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { AppRoute } from 'app/routesEnum';
import { ErrorBoundary, SiteLevelError } from 'shared/components/error-boundary/error-boundary.component';
import { LazyBoundary } from 'shared/components/lazy/lazy-boundary.component';
import { Header } from '../header/header.component';

import { SystemDisruption } from '@shared/components/system-disruption/system-disruption.component';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const PageWrapper = props => {
  const pathname = useSelector(state => state.router.location.pathname);
  const { loginSystemDisruptionWeb } = useFlags();
  const [open, setOpen] = useState(false)

  const closeNotificationCenter = useCallback(() => {
    // Unsets Background Scrolling to use when SideDrawer is closed
    document.body.style.overflow = 'unset';
    setOpen(false)
  },[setOpen])

  const toggleNotificationCenter = useCallback(() => {
    setOpen(prev => !prev)
    // Disables Background Scrolling whilst the SideDrawer is open
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
    
  },[setOpen])

  return (
    <ErrorBoundary render={() => <SiteLevelError className="text-center space-outer-top-lg space-inner-left-md space-outer-right-md" />}>
      <div
        className={classNames(
          'body-wrapper',
          'page-wrapper-component',
          { 'is-home': pathname === AppRoute.HOME },
        )}
      >
        <Header toggleNotification={toggleNotificationCenter}/>
       
        <main className="body-content">
        {loginSystemDisruptionWeb ? <SystemDisruption pageNameResourceKey="LOGIN" disruptionType='LOGIN_LEVEL_DISRUPTION'/> :
          <ErrorBoundary errorType="PAGE_LEVEL_ERROR">
             <LazyBoundary>{props.children}</LazyBoundary>
             <NotificationCenterFeed open={open} onClose={closeNotificationCenter}/>
          </ErrorBoundary>}
        </main>
      </div>
    </ErrorBoundary>
  );
};

import { combineReducers } from 'redux';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { FETCH_LOAD_DETAILS, FETCH_LOAD_DETAILS_FAILURE, FETCH_LOAD_DETAILS_SUCCESS, SET_LOAD_DETAILS } from '../load-details.actions';
import { ExtendedLoad } from 'shared/models/loads/extended-load.model';
import { documents, LoadDetailsDocumentsState } from '../sections/documents/documents.reducers';
import { ContainerUpdateState, ContainerUpdateReducer } from '../sections/container-update/container-update.reducers';
import { waybill, WaybillState } from '../sections/waybill/waybill.reducer';
import { cashAdvanceOptionsReducer } from '../sections/cash-advance/redux/cash-advance-options.reducers';
import { CashAdvanceOptions } from 'shared/models/cash-advances/cash-advance-options.model';

export const load = (state = null, action) => {
  switch (action.type) {
    case SET_LOAD_DETAILS:
      return action.load;
    case FETCH_LOAD_DETAILS_FAILURE:
      return null;
    default:
      return state;
  }
};

export const error = (state = null, action) => {
  switch (action.type) {
    case FETCH_LOAD_DETAILS_FAILURE:
      return action.err;
    case FETCH_LOAD_DETAILS:
    case FETCH_LOAD_DETAILS_SUCCESS:
      return null;
    default:
      return state;
  }
};

export const loadDetailsReducers = combineReducers({
  load,
  error,
  documents,
  waybill,
  cashAdvanceOptions: cashAdvanceOptionsReducer,
  containerUpdate : ContainerUpdateReducer
});

export interface LoadDetailsState {
  load: ExtendedLoad;
  error: AjaxError;
  documents: LoadDetailsDocumentsState;
  waybill: WaybillState;
  cashAdvanceOptions: CashAdvanceOptions;
  containerUpdate: ContainerUpdateState;
}

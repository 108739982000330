import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useButtonClickStream } from '@shared/components/click-stream/use-button-clickstream.hook';
import { Anchor } from '@shared/components/elements/anchor.component';
import { ButtonType } from '@shared/models/click-stream/button-type.model';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { CustomContentCardData } from './types';

export interface CustomContentCardProps {
  card: CustomContentCardData;
  handleCardClick?: (card: CustomContentCardData) => void;
  handleCTAClick?: (card: CustomContentCardData) => void;
}

export const NotificationContentCard = ({ card, handleCardClick, handleCTAClick }: CustomContentCardProps) => {
  const cardStyles = {
    backgroundColor: card.clicked || card.viewed ? '#ffff' : '#F1F8FF',
    margin: '8px 0px',
    border: card.clicked || card.viewed ? '1px solid #E5E6E7' : '1px solid #0071C7',
    cursor: 'pointer',
  };

  const cardHeaderStyles = {
    padding: '8px 16px',
  };

  const dotStyles = {
    height: '6px',
    width: '6px',
    borderRadius: '50%',
    backgroundColor: '#0071C7',
    marginRight: '4px',
    display: card.clicked || card.viewed ? 'none' : 'block'
  };

  const normalizedEventName = useMemo(()=> {
    if(card.title){
      return card.title.replace(/\s+/g, '-').toLowerCase();
    }
    return ''
  },[card.title])

  const clickStream = useButtonClickStream(`btn-notification-card-read-${normalizedEventName}`, ButtonType.Button, true);

  const handleClick = useCallback(
    e => {
      clickStream(e);
      if (!card.viewed) {
        handleCardClick(card);
      }
    },
    [card]
  );

  const handleCTA = useCallback(
    e => {
      e.stopPropagation();
      window.open(card.url, '_blank', 'noreferrer');
      if (!card.clicked) {
        handleCTAClick(card);
      }
    },
    [card]
  );

  const dateFormat = (date) => {
    const currentDate = moment();
    return currentDate.isSame(date, 'day') ? '[TODAY] h:mm A' : 'MMM D h:mm A'
  }

 

  return (
    <Card sx={cardStyles} elevation={0} onClick={handleClick} data-testid="view-notification-button">
      <CardContent sx={cardHeaderStyles}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box sx={dotStyles} />
            <Typography variant="h5">{card.title}</Typography>
          </Box>
          <Typography variant="body2" sx={{ color: '#545658' }}>
            {moment(card.created).format(dateFormat(card.created))}
          </Typography>
        </Box>
      </CardContent>
      <CardContent sx={{ paddingTop: 0 }}>
        <Typography variant="body2" sx={{ color: '#545658' }}>
          {card.description}
        </Typography>
      </CardContent>
      {!!card.linkText && (
        <CardActions sx={{ justifyContent: 'flex-end', padding: '0px 16px 16px 16px' }} disableSpacing>
          <Anchor
            track
            id={`btn-notification-card-${normalizedEventName}`}
            className="cap-benefit-link"
            onClick={handleCTA}
            data-testid="cta-notification-button"
          >
            {card.linkText}
          </Anchor>
        </CardActions>
      )}
    </Card>
  );
};

import { AnyAction, combineReducers } from 'redux';

import { myLoadsSearchReducer, MyLoadsSearchState } from '../search/my-loads-search.reducers';
import { editTrailer } from '../edit-trailer/edit-trailer.reducer';
import { FormGroupStructure } from 'app/forms/structure/form-group.structure';
import { LoadSummary } from 'shared/models/loads/load-summaries/load-summary.model';
import { TOGGLE_SELECTED_LOAD, UPDATE_LOADS } from './my-loads.actions';
import { MY_LOADS_SEARCH_FAILURE, MY_LOADS_SEARCH_SUCCESS } from '../search/my-loads-search.actions';
import { NotificationActionTypes } from 'store/epics/notifications/notifications.actions';

export const loads = (state = [], action: AnyAction) => {
  switch (action.type) {
    case MY_LOADS_SEARCH_SUCCESS:
      return action.payload.results;
    case UPDATE_LOADS:
      return action.loads;
    case MY_LOADS_SEARCH_FAILURE:
      return [];
    case NotificationActionTypes.BOOK_TRACKING_STATUS_NOTIFICATION_RECEIVED:
      var updatedState = [...state];
      var load = updatedState.find(l => l.number == action.payload.shipmentId);
      if (!!load) {
        load.trackingMessageCode = action.payload.trackingMessageCode;
      }
      return updatedState;
    default:
      return state;
  }
};

export const totalRecords = (state = 0, action: AnyAction) => {
  switch (action.type) {
    case MY_LOADS_SEARCH_SUCCESS:
      return action.payload.totalRecords;
    default:
      return state;
  }
};

export const loadSelections = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case TOGGLE_SELECTED_LOAD:
      return {
        ...state,
        [`${action.load.number}-${action.load.bookSequenceNumber}`]: action.isSelected
      };
    case MY_LOADS_SEARCH_SUCCESS:
    case MY_LOADS_SEARCH_FAILURE:
      return {};
    default:
      return state;
  }
};

export const myLoadsReducers = combineReducers({
  search: myLoadsSearchReducer,
  results: combineReducers({loads, totalRecords}),
  loadSelections,
  editTrailer,
});

export interface MyLoadsState {
  search: MyLoadsSearchState;
  results: {
    loads: LoadSummary[],
    totalRecords: number;
  };
  loadSelections: {[key: string]: boolean};
  editTrailer: { structure: FormGroupStructure };
}

import { Fragment, ReactNode, useEffect } from 'react';
import { withLDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import Container from 'typedi';
import { setLdUserIdentity } from 'features/analytics/redux/analytics-v2.actions';
import { useDispatch } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { LDEvaluationDetail, LDInspectionFlagUsedHandler, LDUser } from 'launchdarkly-js-client-sdk';
import { User } from '@shared/models/user.model';
import { CarrierDetail } from '@shared/models/carrier/carrier-detail.model';
import { CarrierDivision } from '@shared/models/carrier/carrier-division.model';

interface Props extends JSX.IntrinsicAttributes{
  children?: ReactNode;
  user?: User;
  carrier?: CarrierDetail;
}

const sendEvents = process.env.NODE_ENV != 'test';

const clientConfig = {
  clientSideID: Container.get<string>('appConstants.launchDarklyClientId'),
  options: {
    sendEvents: sendEvents, // send analytics events to launch darkly
    diagnosticOptOut: true, // opt out of diagnostics data to LaunchDarkly
    flushInterval: 2000, // interval inbetween flushes of the analytics events queue in ms
    eventCapacity: 500, // capacity of the analytics event queue
    inspectors: [
      {
        type: "flag-used",
        name: "dd-inspector",
        method: (flagKey: string, flagDetail: LDEvaluationDetail, user: LDUser) => {
          datadogRum.addFeatureFlagEvaluation(flagKey, flagDetail.value);
        },
      } as LDInspectionFlagUsedHandler,
    ]
  }
};

const FeatureFlagComponent = ({ children, user, carrier }: Props) => {
  const ldClient = useLDClient();
  const dispatch = useDispatch();

  useEffect(() => {
    Container.set('LD_CLIENT', ldClient);

    if (user && ldClient) {
      ldClient
        .identify({ key: `${user.userId}`, 
          custom: { 
            carrierCode: user.carrierCode,
            rollupDivisions: CarrierDivision.retrieveRollupDivisions(user),
            carrierSize: carrier?.carrierSize,
          } })
        .then(() => dispatch(setLdUserIdentity(true)));
      ldClient.flush();
    }
  }, [user, ldClient, carrier])


  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

export const FeatureFlagProvider = withLDProvider<Props>(clientConfig)(FeatureFlagComponent);

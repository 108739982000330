import React from 'react';
import { Badge } from '@mui/material';
import { useBrazeContext } from './initialization';
import { Button } from '@shared/components/elements/button.component';
import { useReduceBrazeCard } from '@app/hooks/use-reduce-braze-card';
import { NotificationBellLogo } from 'utils/NotificationBellLogo';

interface IProps {
  onClick: () => void;
  className?: string;
}

export const NotificationButton: React.FC<IProps> = ({ onClick, className }) => {
  const { contentCards } = useBrazeContext();

  const { countCards } = useReduceBrazeCard(contentCards);

  return (
    <Button
      track
      id="btn-notification-bell"
      aria-controls="notification-center-toggle"
      className={className}
      onClick={onClick}
    >
      <Badge badgeContent={countCards} color="primary" data-testid="notification-badge-button">
        <NotificationBellLogo 
          className="notification-bell-icon" 
          alt="Notification Bell"
        />
      </Badge>
    </Button>
  );
};

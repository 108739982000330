import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { Cookie } from 'ng2-cookies';

import { useForceRefreshToken } from 'app/hooks/use-force-refresh-token.hook';
import { useStoredUser } from 'app/hooks/store/use-stored-user.hook';

export const useDismissal = (): [boolean, () => void] => {
  const user = useStoredUser();
  const today = moment().startOf('day').format('L');
  const [forceRefreshToken, forceRefresh] = useForceRefreshToken();

  const dismissed = useMemo(() => Cookie.get('maintenance-dismissal') === 'true', [forceRefreshToken, user, today]);

  const dismiss = useCallback(() => {
    Cookie.set('maintenance-dismissal', 'true', moment().add(1, 'day').startOf('day').toDate());
    forceRefresh();
  }, [forceRefreshToken, user, today]);

  return [dismissed, dismiss];
};

import classNames from 'classnames';
import React from 'react';
import { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { AppRoute } from 'app/routesEnum';
import { logOut } from '@features/okta/redux/oktaSlice';
import { Feedback } from '../feedback/feedback.component';
import { Translation } from 'shared/components/translation/translation.component';
import { CultureSelect } from './culture-select/culture-select.component';
import { NavItem } from './nav-items/nav-item.component';
import { TendersNavItem } from './nav-items/tenders-nav-item.component';
import { SubMenu } from './sub-menu/sub-menu.component';
import { Button } from 'shared/components/elements/elements.components';
import { Grants } from 'features/security/grants';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useResource } from 'app/hooks/store/use-resource.hook';
import { useMenuControls } from './hooks/use-menu-controls.hook';
import { useFeedbackModal } from './hooks/use-feedback-modal.hook';
import { useCarrierAdvantage } from 'app/hooks/feature-flags/use-carrier-advantage.hook';
import { useIsPaymentsUser } from 'pages/payments/hooks/use-is-payments-user.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CashAdvanceTeaching } from 'shared/my-loads/components/cash-advance-teaching.component';
import { NotificationButton } from '@features/braze/notification-button';
import LaunchIcon from '@mui/icons-material/Launch';
import Box from '@mui/material/Box';
import MUIButton from '@mui/material/Button';
import CHRNavCarrierBrandLogo from '/assets/images/CHR_NavCarrier_Brand_Logo_resized.svg';
import { toggleMembershipDialog, setMembershipDialogView, CompanySelectionDialogViews } from 'features/permissions';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ChrBrandLogo } from 'utils/ChrBrandLogo';
import { useDispatch } from 'react-redux';
import { ApprovedCarrierQualificationsStatuses } from 'app/globals/constants';
import { AuthAction, useAction } from '@features/datadog';

import './header.component.scss';
import './utility-nav.scss';
import './hamburger.scss';


interface IProps {
  toggleNotification: () => void;
}

export const Header: React.FC<IProps> = ({ toggleNotification }) => {
  const user = useSelector(state => state.auth.user);
  const carrier = useSelector(state => state.auth.carrier);
  const { memberships, activeMembership } = useSelector(s => s.userManagement);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();
  const track = useAction();
  const NAVIGATION_MENU = useResource('NAVIGATION_MENU');

  const utilMenu = useMenuControls();
  const mobileMenu = useMenuControls();

  const [showMainFeedback, hideMainFeedback] = useFeedbackModal('feedback');

  const carrierAdvantage = useCarrierAdvantage();
  const { carrierCapProfile, enableV2CapDashboard, webPreferencesNotificationsBraze, enableUserManagement } = useFlags();
  const isPaymentsUser = useIsPaymentsUser();

  const companyName = activeMembership?.attributes?.companyName || carrier?.name;
  const companyCode = activeMembership?.companyCode || user?.carrierCode;
  const showChangeAccount = memberships?.length > 1;
  const showFindLoads = carrier?.carrierQualificationStatus
    ? ApprovedCarrierQualificationsStatuses.includes(carrier.carrierQualificationStatus.toUpperCase())
    : false;

  const onLogout = useCallback(() => {
    oktaAuth.signOut();
    oktaAuth.tokenManager.clear();
    track(AuthAction.ManualLogout);
    dispatch(logOut());
    utilMenu.close();
    mobileMenu.close();
  }, [useDispatch, utilMenu, mobileMenu]);

  const navigateToHelpFaq = (): void => {
    window.open('https://www.chrobinson.com/en-us/carriers/carrier-support/', '_blank');
    utilMenu.close();
  };

  const setCompanyDialogOpen = () => {
    dispatch(toggleMembershipDialog(true));
    dispatch(setMembershipDialogView(CompanySelectionDialogViews.CHANGE_COMPANY));

  };

  if (window.location.pathname === '/login') {
    return null;
  }

  if (isAuthenticated) {
    return (
      <header className="header-component internal-header">
        {/*--Start of non-mobile header--*/}
        <div className="site-header">
          <nav aria-label="Secondary Menu" className="upper-menu" ref={utilMenu.ref}>
            <span className="carrier-logo" aria-hidden={true}>
              <ChrBrandLogo className="chr-brand-svg" alt="NavisphereCarrier" />
            </span>
            <ul>
              <li>
                <NavItem url={AppRoute.DOCK_MANAGER} target="_blank" resourceKey="DOCK_MANAGER" />
              </li>
              <li>
                <Button className="js-navbar-feedback-button" onClick={showMainFeedback} resource="FEEDBACK" />
              </li>
              {webPreferencesNotificationsBraze && !user?.isEuropean() && (
                <li>
                  <NotificationButton className="notification-btn" onClick={toggleNotification} />
                </li>
              )}

              <li className={classNames('utility-nav', { open: utilMenu.isOpen })}>
                <button
                  type="button"
                  id="headerUtilMenuButton"
                  title={`${user.getDisplayName()} ${NAVIGATION_MENU}`}
                  className="user-info js-account-menu-user-name"
                  aria-controls="utility-menu-collapsible"
                  aria-expanded={utilMenu.isOpen}
                  onClick={utilMenu.toggle}
                >
                  <svg aria-hidden="true" className="user-icon" viewBox="0 0 20 20" width="100%" height="100%">
                    <title>
                      <Translation resource="USER" />
                    </title>
                    <path
                      d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,
                          4.48 15.52,0 10,0 Z M10,3 C11.66,3 13,4.34 13,6 C13,7.66 11.66,9 10,9 C8.34,9 7,
                          7.66 7,6 C7,4.34 8.34,3 10,3 Z M10,17.2 C7.5,17.2 5.29,15.92 4,13.98 C4.03,
                          11.99 8,10.9 10,10.9 C11.99,10.9 15.97,11.99 16,13.98 C14.71,15.92 12.5,17.2 10,17.2 Z"
                      id="path-1"
                    />
                  </svg>
                  <Stack sx={{ alignItems: 'flex-start' }}>
                    <Typography variant="subtitle2" sx={{ color: '#ffffff' }}>
                      {user.getDisplayName()}
                    </Typography>
                    {enableUserManagement &&
                      <Typography variant="caption">
                        {activeMembership?.attributes?.companyName}
                      </Typography>
                    }
                  </Stack>
                </button>
                <div id="utility-menu-collapsible" className={classNames('utility-menu', { open: utilMenu.isOpen })}>
                  <div className="utility-menu-header fs-mask">
                    <span className="font-bold">{user.getDisplayName()}</span>
                    <br />
                    {companyName}
                    <br />
                    {companyCode}
                    <br />
                    {showChangeAccount &&
                      <MUIButton
                        variant="text"
                        onClick={() => setCompanyDialogOpen()}
                        sx={{ width: 'auto', padding: '0px', height: '24px' }}
                      >
                        <Translation resource="CHANGE_ACCOUNT" />
                      </MUIButton>
                    }
                  </div>
                  <ul>
                    {carrierCapProfile && carrierAdvantage && !enableV2CapDashboard && (
                      <li className="secondary-menu-item">
                        <NavItem url={AppRoute.DASHBOARD} resourceKey="CARRIER_ADVANTAGE" />
                      </li>
                    )}
                    <li className="secondary-menu-item">
                      <NavItem url={AppRoute.ACCOUNT_SETTINGS} resourceKey="ACCOUNT_SETTINGS" onClick={utilMenu.close} />
                    </li>
                    <li className="secondary-menu-item">
                      <button id="help-faq-btn" className="help-and-faq-container" onClick={navigateToHelpFaq}>
                        <div className="help-and-faq-item">
                          <Translation resource="HELP_AND_FAQ" />
                        </div>
                        <LaunchIcon fontSize='small' />
                      </button>
                    </li>
                    <li className="secondary-menu-item">
                      <NavItem url={AppRoute.CONTACT_US} resourceKey="CONTACT_US" onClick={utilMenu.close} />
                    </li>
                    <li className="secondary-menu-item">
                      <button id="logoutButton" onClick={onLogout} className="js-logout-button js-logout-desktop">
                        <Translation resource="LOGOUT" />
                      </button>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
          <nav aria-label="Main Menu" className="lower-menu">
            <ul className="js-main-menu">
              {enableV2CapDashboard && carrierAdvantage && (
                <li>
                  <NavItem
                    url={AppRoute.DASHBOARD}
                    subUrls={[AppRoute.CAP_SCORE_DETAILS, AppRoute.CARRIER_ADVANTAGE_PROGRAM]}
                    resourceKey="CARRIER_ADVANTAGE"
                  />
                </li>
              )}
              {
                // check if this button can be removed
                !carrierCapProfile && carrierAdvantage && (
                  <li>
                    <NavItem
                      className="main-menu_carrier-advantage"
                      url={AppRoute.DASHBOARD}
                      resourceKey="CARRIER_ADVANTAGE"
                    />
                  </li>
                )
              }
              {showFindLoads && <SubMenu
                grant={Grants.FindLoadsGrant}
                menuTriggerLabel="FIND_LOADS"
                className="main-menu_find-loads"
                ariaControls="site-find-loads-collapsible"
                subMenuItems={[
                  { pageName: 'FIND_LOADS', pageUrl: AppRoute.FIND_LOADS_BASE },
                  { pageName: 'PREFERRED_LANES', pageUrl: AppRoute.PREFERRED_LANES },
                ]}
              />}
              <li>
                <NavItem url={AppRoute.OFFERS} resourceKey="MY_OFFERS" />
              </li>
              <li>
                <NavItem url={AppRoute.POST_TRUCKS} resourceKey="POST_TRUCKS" />
              </li>
              <li className="main-menu_my-loads">
                <NavItem url={AppRoute.MY_LOADS} resourceKey="MY_LOADS" />
                <CashAdvanceTeaching />
              </li>

              <li>
                <NavItem url={AppRoute.MY_SHIPMENTS} resourceKey="MY_SHIPMENTS" />
              </li>
              <li>
                <NavItem url={AppRoute.RATES} resourceKey="RATES" />
              </li>
              <li>
                <NavItem url={AppRoute.SPOT_BIDS} resourceKey="SPOT_BIDS" />
              </li>
              <li className="tenders-navbar-item">
                <TendersNavItem url={AppRoute.TENDERS} resourceKey="TENDERS" />
              </li>
              {isPaymentsUser && (
                <SubMenu
                  grant={Grants.PaymentsGrant}
                  menuTriggerLabel="PAYMENTS_"
                  ariaControls="site-payments-collapsible"
                  subMenuItems={[
                    { pageName: 'PAYMENTS_', pageUrl: AppRoute.PAYMENTS },
                    { pageName: 'CLAIMS', pageUrl: AppRoute.CLAIMS },
                  ]}
                />
              )}
              {!isPaymentsUser && (
                <SubMenu
                  grant={Grants.AccountsReceivableGrant}
                  menuTriggerLabel="ACCOUNTS_RECEIVABLE"
                  ariaControls="site-accounts-receivable-collapsible"
                  subMenuItems={[
                    { pageName: 'ACCOUNTS_RECEIVABLE', pageUrl: AppRoute.ACCOUNTS_RECEIVABLE },
                    { pageName: 'CLAIMS', pageUrl: AppRoute.CLAIMS },
                  ]}
                />
              )}
              <li>
                <NavItem url={AppRoute.MANAGE_INVOICES} resourceKey="MANAGE_INVOICES" />
              </li>
            </ul>
          </nav>
        </div>
        {/*--End of non-mobile header--*/}
        {/*Start of mobile header*/}
        <nav className="header-component mobile-header" ref={mobileMenu.ref}>
          <div className="upper-menu">
            <span className="carrier-logo" aria-hidden={true}>
              <ChrBrandLogo className="chr-brand-svg" alt="NavisphereCarrier" />
            </span>
            <Box display="flex">
              {webPreferencesNotificationsBraze && <NotificationButton className="notification-btn" onClick={toggleNotification} />}

              <button
                className={classNames('hamburger', { open: mobileMenu.isOpen })}
                title={NAVIGATION_MENU}
                aria-controls="mobile-menu-collapsible"
                aria-expanded={mobileMenu.isOpen}
                onClick={mobileMenu.toggle}
              >
                <span className="line" />
                <span className="line" />
                <span className="line" />
              </button>
            </Box>
          </div>
          <ul id="mobile-menu-collapsible" className={classNames('mobile-menu', { open: mobileMenu.isOpen })}>
            <li>
              <CashAdvanceTeaching />
            </li>
            {enableV2CapDashboard && carrierAdvantage && (
              <li>
                <NavItem
                  url={AppRoute.DASHBOARD}
                  subUrls={[AppRoute.CAP_SCORE_DETAILS, AppRoute.CARRIER_ADVANTAGE_PROGRAM]}
                  resourceKey="CARRIER_ADVANTAGE"
                />
              </li>
            )}
            <SubMenu
              grant={Grants.FindLoadsGrant}
              triggerRoutes={true}
              triggerLink={AppRoute.FIND_LOADS_BASE}
              menuTriggerLabel="FIND_LOADS"
              ariaControls="mobile-find-loads-collapsible"
              subMenuItems={[{ pageName: 'PREFERRED_LANES', pageUrl: AppRoute.PREFERRED_LANES }]}
            />
            <li>
              <NavItem url={AppRoute.OFFERS} resourceKey="MY_OFFERS" />
            </li>
            <li>
              <NavItem url={AppRoute.POST_TRUCKS} resourceKey="POST_TRUCKS" />
            </li>
            <li>
              <NavItem url={AppRoute.MY_LOADS} resourceKey="MY_LOADS" />
            </li>
            <li>
              <NavItem url={AppRoute.RATES} resourceKey="RATES" />
            </li>
            <li>
              <NavItem url={AppRoute.SPOT_BIDS} resourceKey="SPOT_BIDS" />
            </li>
            <li>
              <TendersNavItem url={AppRoute.TENDERS} resourceKey="TENDERS" />
            </li>
            <SubMenu
              grant={Grants.AccountsReceivableGrant}
              triggerRoutes={true}
              triggerLink={AppRoute.ACCOUNTS_RECEIVABLE}
              menuTriggerLabel="ACCOUNTS_RECEIVABLE"
              ariaControls="mobile-accounts-receivable-collapsible"
              subMenuItems={[{ pageName: 'CLAIMS', pageUrl: AppRoute.CLAIMS }]}
            >
              <li>
                <NavItem url={AppRoute.CLAIMS} resourceKey="CLAIMS" />
              </li>
            </SubMenu>
            <li>
              <NavItem url={AppRoute.MANAGE_INVOICES} resourceKey="MANAGE_INVOICES" />
            </li>
            <div className="user-info fs-mask">
              <svg aria-hidden="true" className="user-icon" viewBox="0 0 20 20" width="100%" height="100%">
                <title>User</title>
                <path
                  d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,
                      4.48 15.52,0 10,0 Z M10,3 C11.66,3 13,4.34 13,6 C13,7.66 11.66,9 10,9 C8.34,9 7,
                      7.66 7,6 C7,4.34 8.34,3 10,3 Z M10,17.2 C7.5,17.2 5.29,15.92 4,13.98 C4.03,
                      11.99 8,10.9 10,10.9 C11.99,10.9 15.97,11.99 16,13.98 C14.71,15.92 12.5,17.2 10,17.2 Z"
                  id="path-1"
                />
              </svg>
              <span>
                <span className="js-account-menu-user-name">{user.getDisplayName()}</span>
                <br />
                <span>{companyName} - {companyCode}</span>
                <br />
                {showChangeAccount &&
                  <MUIButton
                    variant="text"
                    onClick={() => setCompanyDialogOpen()}
                    sx={{ width: 'auto', padding: '0px', height: '24px' }}
                  >
                    <Translation resource="CHANGE_ACCOUNT" />
                  </MUIButton>
                }
              </span>
            </div>
            {carrierCapProfile && carrierAdvantage && !enableV2CapDashboard && (
              <li className="secondary-menu-item">
                <NavItem url={AppRoute.DASHBOARD} resourceKey="CARRIER_ADVANTAGE" />
              </li>
            )}
            <li className="secondary-menu-item">
              <NavItem url={AppRoute.DOCK_MANAGER} target="_blank" resourceKey="DOCK_MANAGER" />
            </li>
            <li className="secondary-menu-item">
              <NavItem url={AppRoute.ACCOUNT_SETTINGS} resourceKey="ACCOUNT_SETTINGS" onClick={utilMenu.close} />
            </li>
            <li className="secondary-menu-item">
              <Button id="help-faq-btn" track className="help-and-faq-container" onClick={navigateToHelpFaq}>
                <div className="help-and-faq-item">
                  <Translation resource="HELP_AND_FAQ" />
                </div>
                <img src="/assets/images/arrow-up-right-white.svg" />
              </Button>
            </li>
            <li className="secondary-menu-item">
              <NavItem url={AppRoute.CONTACT_US} resourceKey="CONTACT_US" onClick={mobileMenu.close} />
            </li>
            <li className="secondary-menu-item">
              <Button className="js-navbar-feedback-button" onClick={showMainFeedback} resource="FEEDBACK" />
            </li>
            <li className="secondary-menu-item">
              <button onClick={onLogout} className="js-logout-button js-logout-mobile">
                <Translation resource="LOGOUT" />
              </button>
            </li>
          </ul>
        </nav>
        {/*End of mobile header*/}
        <Feedback onComplete={hideMainFeedback} onCancel={hideMainFeedback} />
      </header>
    );
  }

  return (
    <header className="external-header">
      <nav aria-label="Main Menu" className="upper-menu">
        <span className="carrier-logo" aria-hidden={true}>
          <ChrBrandLogo className="chr-brand-svg" alt="NavisphereCarrier" />
        </span>
        <NavLink to="/login" id="ancLogIN">
          <Translation resource="LOGIN" />
        </NavLink>
      </nav>
      {window.location.pathname !== '/login' && <CultureSelect />}
    </header>
  );
};
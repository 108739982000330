import { buildUrl, post } from '@lib/api';
import Result from "@lib/result";

const baseUrl = apiConfig.gatewayAPI;

export async function callCarrierValidation(loadNumber: number): Promise<Result<boolean>> {
  const url = buildUrl(baseUrl, `/CarrierValidation/Validate/${loadNumber}`);
  const result = await post<CarrierValidationResult>(url);

  return result.success
    ? Result.Success(result.value.isValid)
    : Result.Fail(result.error);
}

export default {
  callCarrierValidation
};
import React from 'react';
import { useCallback, useMemo } from 'react';

import { Button } from 'shared/components/elements/elements.components';
import { Modal } from 'shared/components/modal/modal.component';
import { InsuranceValidation } from 'shared/components/insurance/insurance-validation.component';
import { confirmInsuranceStatus } from 'features/security/auth.actions';
import { useModal } from 'app/hooks/use-modal.hook';
import { useCarrierDetails } from 'app/hooks/store/use-carrier-details.hook';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';

import './insurance-validation-modal.component.scss';

export const InsuranceValidationModal: React.FC = () => {
  const currentCarrier = useCarrierDetails();
  const pendingCarrier = useSelector(state => state.auth.pending.carrier);
  const carrier = pendingCarrier || currentCarrier;
  const confirmInsurance = useDispatcher(confirmInsuranceStatus);

  const [, hide] = useModal('insurance-expired');
  const confirm = useCallback(() => pendingCarrier ? confirmInsurance() : hide(), [pendingCarrier]);

  const headerKey = useMemo(() =>
      carrier
        ? (carrier.hasExpiredInsurance() ? 'INSURANCE_EXPIRED' : 'INSURANCE_EXPIRING_SOON')
        : null,
    [carrier]
  );
  return (
    <Modal
      disableClose
      name="insurance-expired"
      header={headerKey}
      footer={<Button btnPrimary resource="OK" onClick={confirm}/>}
    >
      <InsuranceValidation suppressHeader/>
    </Modal>
  );
};

import React from 'react';

import { Label } from 'shared/components/elements/elements.components';
import { Translation } from 'shared/components/translation/translation.component';
import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';
import { useCarrierDetails } from 'app/hooks/store/use-carrier-details.hook';
import { useResources } from 'app/hooks/store/use-resources.hook';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { PageHeader } from 'containers/layout/page-header/page-header.component';

import './insurance-validation.component.scss';

interface OwnProps {
  suppressHeader?: boolean;
  pendingCarrier: CarrierDetail;
  carrier: CarrierDetail;
}

type Props = OwnProps;

export const InsuranceValidationComponent: React.FC<Props> = ({suppressHeader, ...props}) => {
  const carrier = props.pendingCarrier || props.carrier;
  const resources = useResources();

  if (carrier && (carrier.hasExpiredInsurance() || carrier.hasExpiringInsurance()) &&
     (carrier.typeOfInsurance === 3 || carrier.typeOfInsurance  === 4)) {
    return (
      Boolean(carrier && resources && Object.keys(resources).length) &&
      <div id="insurance-validation-page">
          <div id="insurance-validation">
            {
              !suppressHeader && (carrier.hasExpiredInsurance()
                ? <h3><Translation resource="INSURANCE_EXPIRED"/></h3>
                : <h3><Translation resource="INSURANCE_EXPIRING_SOON"/></h3>)
            }
              <div>
                  <div>
                      <p>
                        {
                          carrier.hasExpiredInsurance()
                            ? <Translation resource="OUR_RECORDS_INDICATE_THAT_YOUR_CARRIER_LIABILITY_I"/>
                            : <Translation resource="OUR_RECORDS_INDICATE_THAT_YOUR_CARRIER_LIABILITY2" replacements={[carrier.daysUntilInsuranceExpiration]}/>
                        }
                      </p>
                  </div>
              </div>
              <div>
                  <Label resource="PLEASE_SUBMIT_YOUR_UPDATED_CERTIFICATE_BY_EMAIL_TO">:</Label>{' '}
                  <a href="mailto:carrier.qualityeu@chrobinson.com">carrier.qualityeu@chrobinson.com</a>
              </div>
          </div>
      </div>
    );
  } else {
    return (
      Boolean(carrier && resources && Object.keys(resources).length) &&
      <div id="insurance-validation-page">
          <div id="insurance-validation">
            {
              !suppressHeader && (carrier.hasExpiredInsurance()
                ? <PageHeader documentTitle="INSURANCE_EXPIRED" header="INSURANCE_EXPIRED"/>
                : <h3><Translation resource="INSURANCE_EXPIRING_SOON"/></h3>)
            }
              <div>
                  <div>
                      <p>
                        {
                          carrier.hasExpiredInsurance()
                            ? <Translation resource="INSURANCE_HAS_EXPIRED"/>
                            : <Translation resource="INSURANCE_WILL_EXPIRE" replacements={[carrier.daysUntilInsuranceExpiration]}/>
                        }
                      </p>
                      <p><Translation resource="IN_ORDER_TO_CONTINUE_BOOKING_LOADS_FOR_C_H_ROBINSO"/></p>
                  </div>
              </div>

              <div className="company-contact">
                  <div>
                      <Label resource="EMAIL">:</Label>{' '}
                      <a href="mailto:transportation@truckstop.com">transportation@truckstop.com</a>
                  </div>
                  <div><Label resource="FAX">:</Label> 818-332-4934</div>
              </div>

              <p><Translation resource="PLEASE_ASK_YOUR_INSURANCE_AGENT_TO_LIST_THE_CERTIF"/></p>

              <div className="company-address">
                  Registry Monitoring Insurance Services, Inc<br/>
                  5388 Sterling Center Drive<br/>
                  Westlake Village, CA 91361
              </div>
          </div>
      </div>
    );
  }
};

interface WrapperProps {
  suppressHeader?: boolean;
}

export const InsuranceValidation: React.FC<WrapperProps> = ({suppressHeader}) => {
  const pendingCarrier = useSelector(state => state.auth.pending.carrier);
  const currentCarrier = useCarrierDetails();
  return <InsuranceValidationComponent pendingCarrier={pendingCarrier} carrier={currentCarrier} suppressHeader={suppressHeader}/>;
};

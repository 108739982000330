import { AppRoute } from '@app/routesEnum';
import { Pages } from '@pages/pages';
import { Route } from 'react-router';

export default function UnSubscribeRoutes() {
  return (
    <>
      <Route path={AppRoute.UNSUBSCRIBE} component={Pages.Unsubscribe} />
      <Route path={AppRoute.UNSUBSCRIBE_REGISTRATION} component={Pages.UnsubscribeRegistration} />
      <Route path={AppRoute.UNSUBSCRIBE_REGISTRATION_ERROR} component={Pages.UnsubscribeRegistrationError} />
      <Route path={AppRoute.UNSUBSCRIBE_ERROR} component={Pages.UnsubscribeError} />
    </>
  );
}

import { Translation, TranslationJSX } from 'shared/components/translation/translation.component';
import { ReactNode, useEffect, useState } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import './animation.component.scss';

const backgroundImages = [
  {
    gif: '/assets/gifs/NavCarrierLogin_A.gif',
    still: '/assets/images/NavCarrierLogin_A_still.png'
  },
  {
    gif: '/assets/gifs/NavCarrierLogin_B.gif',
    still: '/assets/images/NavCarrierLogin_B_still.png'
  }
];

const backgroundImagesMid = [
  '/assets/images/NavCarrierLoginBannerMid_A.jpg',
  '/assets/images/NavCarrierLoginBannerMid_B.jpg'
];

const backgroundImagesSmall = [
  '/assets/images/NavCarrierLoginBannerSmall_A.jpg',
  '/assets/images/NavCarrierLoginBannerSmall_A.jpg'
];

export const Animation = ({ children }: { children: ReactNode }) => {
  const [randomIndex, setRandomIndex] = useState<number|undefined>();
  const [playingAnimation, setPlayingAnimation] = useState<boolean>(true); 

  const togglePlay = () => {
    setPlayingAnimation(prev => !prev);
  };

  const getLargeBackgroundBanner = () => {
    if (playingAnimation) return backgroundImages[randomIndex]?.gif;
    return backgroundImages[randomIndex]?.still;
  };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * backgroundImages.length);
    setRandomIndex(randomIndex);
  }, []);

  return (
    <>
      <div className="animationMainContainer">
        <div className="Logincontainer">
          <div className={'leftContainer leftContainerHide'}>
            <div className="pausePlayContainer">
              <button id="stopAnimation" className="playBtn" onClick={togglePlay}>
                {playingAnimation ? 'Pause': 'Play'}
                {playingAnimation ? <PauseIcon/> : <PlayArrowIcon/>}
              </button>
            </div>
            <img src={getLargeBackgroundBanner()} alt="" className="img-banner-large" />
            <img src={backgroundImagesMid[randomIndex]} alt="" className="img-banner-mid" />
            <img src={backgroundImagesSmall[randomIndex]} alt="" className="img-banner-small" />
          </div>
          <div className="rightContainer">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Animation;

import { ActionsObservable, combineEpics } from 'redux-observable';
import { Action, AnyAction } from 'redux';
import { Container } from 'typedi';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/switchMap';

import * as a from 'shared/offers/redux/offers.actions';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { AvailableLoadsRepository } from 'app/repositories/available-loads.repository';
import { Offer } from 'shared/models/offers/offer.model';
import { popupWindows } from 'app/browser/popups.epics';
import { InternalAPI } from 'app/browser/window';
import { fetchOffers, updateOfferStatusFromSignalR } from 'shared/offers/redux/offers.actions';
import { Translation } from 'shared/components/translation/translation.component';
import { ToastManager } from 'shared/components/toast/toast.actions';
import { NotificationActionTypes } from 'store/epics/notifications/notifications.actions';
import { updateOfferStatus } from 'shared/find-loads/redux/find-loads.actions';
import { OfferStatus } from '../../enums/offer-status.enum';

interface Deps {
  repo: AvailableLoadsRepository;
}

type OffersEpic<OutputAction extends Action = AnyAction> = NavCarrierEpic<OutputAction, Deps>;

export const fetchOffersEpic: OffersEpic = (action$, state$, {repo}) => {
  const toasts = Container.get(ToastManager);
  return action$.ofType(a.FETCH_OFFERS)
    .switchMap(() =>
      repo.getOffers()
        .map(resultSet => resultSet.results)
        .map((offers: Offer[]) => a.fetchOffersSuccess(offers))
        .catch((error: AjaxError) => {
            toasts.error([<Translation resource="AN_ERROR_OCCURED_ATTEMPTING_TO_LOAD_THE_PAGE"/>]);
            return Observable.of(a.fetchOffersFailure(error));
          }
        )
    );
};

export const refreshOffersEpic: OffersEpic = (action$) =>
  action$.ofType(a.REFRESH_OFFERS)
    .mapTo(fetchOffers());

export const refreshPopupOffersEpic: OffersEpic<a.RefreshOffersOnWindowAction> = (action$) =>
  action$.ofType(a.REFRESH_OFFERS)
    .filter(() => popupWindows.items['FindLoadDetails'] != null)
    .map(() => a.refreshOffersOnWindow(popupWindows.items['FindLoadDetails']));

export const refreshParentOffersEpic: OffersEpic<a.RefreshOffersOnWindowAction> = (action$) =>
  action$.ofType(a.REFRESH_OFFERS)
    .filter(() => window.opener != null)
    .map(() => a.refreshOffersOnWindow(window.opener))
    .catch(error => {
        return ActionsObservable.empty();
      }
    );

export const refreshOffersOnWindowEpic: OffersEpic = action$ =>
  action$.ofType<a.RefreshOffersOnWindowAction>(a.REFRESH_OFFERS_ON_WINDOW)
    .map(({target}) => target[Container.get<string>('appConstants.internalAPIKey')] as InternalAPI)
    .filter(API => (API && API.fetchOffers) != null)
    .do(API => API.fetchOffers())
    .catch(error =>
      Observable.empty()
  ).ignoreElements();

export const updateOffersOfferStatusEpic: OffersEpic = (action$, state$) =>
  action$.ofType(NotificationActionTypes.OFFER_RESULT_NOTIFICATION_RECEIVED)
    .map(({ payload }) => {
      const notification = payload as OfferResultNotification;
      switch (notification.offerNegotiationResult) {
        case 0: // Accepted
          return updateOfferStatusFromSignalR({
            loadNumber: notification.loadNumber,
            offerRequestId: notification.transactionId,
            resultReceivedDate: notification.receivedDate,
            offerId: notification.offerId,
            currencyCode: notification.currencyCode,
            price: notification.price,
            isFinalNegotiation: notification.isFinalNegotiation ?? true,
            updatedStatus: OfferStatus.ACCEPTED,
          });
        case 1: // Rejected
          return updateOfferStatusFromSignalR({
            offerId: notification.offerId,
            loadNumber: notification.loadNumber,
            offerRequestId: notification.transactionId,
            resultReceivedDate: notification.receivedDate,
            updatedStatus: OfferStatus.REJECTED,
            isFinalNegotiation: notification.isFinalNegotiation ?? true,
            rejectionReason: notification.rejectionReason,
          }
          );
        case 2: // Not Considered
          return updateOfferStatusFromSignalR({
            offerId: notification.offerId,
            loadNumber: notification.loadNumber,
            offerRequestId: notification.transactionId,
            resultReceivedDate: notification.receivedDate,
            isFinalNegotiation: notification.isFinalNegotiation ?? true,
            updatedStatus: OfferStatus.NOT_CONSIDERED,
          });
        case 3: // Countered
          return updateOfferStatusFromSignalR({
            loadNumber: notification.loadNumber,
            offerRequestId: notification.transactionId,
            resultReceivedDate: notification.receivedDate,
            updatedStatus: OfferStatus.COUNTERED,
            currencyCode: notification.currencyCode,
            offerId: notification.offerId,
            isFinalNegotiation: notification.isFinalNegotiation ?? true,
            price: notification.price
          });
        default:
          return updateOfferStatusFromSignalR({
            offerId: notification.offerId,
            loadNumber: notification.loadNumber,
            offerRequestId: notification.transactionId,
            resultReceivedDate: notification.receivedDate,
            isFinalNegotiation: notification.isFinalNegotiation ?? true,
            updatedStatus: OfferStatus.UNABLE_TO_PROCESS
          });
      }
    }
    );

export const updateOfferStatusEpic: OffersEpic = (action$, state$) =>
  action$.ofType(NotificationActionTypes.OFFER_RESULT_NOTIFICATION_RECEIVED)
    .map(({ payload }) => {
      const notification = payload as OfferResultNotification;
      switch (notification.offerNegotiationResult) {
       case 0: // Accepted
        return updateOfferStatus({
            loadNumber: notification.loadNumber,
            offerRequestId: notification.transactionId,
            resultReceivedDate: notification.receivedDate,
            offerId: notification.offerId,
            updatedStatus: 'ACCEPTED'
          });
        case 1: // Rejected
          return updateOfferStatus({
            offerId: notification.offerId,
            loadNumber: notification.loadNumber,
            offerRequestId: notification.transactionId,
            resultReceivedDate: notification.receivedDate,
            updatedStatus: 'REJECTED',
            isFinalNegotiation: notification.isFinalNegotiation,
            rejectionReason: notification.rejectionReason,
          });
        case 2: // Not Considered
          return updateOfferStatus({
            loadNumber: notification.loadNumber,
            offerRequestId: notification.transactionId,
            resultReceivedDate: notification.receivedDate,
            updatedStatus: 'NOT_CONSIDERED'
          });
        case 3: // Countered
          return updateOfferStatus({
            loadNumber: notification.loadNumber,
            offerRequestId: notification.transactionId,
            resultReceivedDate: notification.receivedDate,
            updatedStatus: 'COUNTERED',
            currencyCode: notification.currencyCode,
            offerId: notification.offerId,
            price: notification.price,
            isFinalNegotiation: notification.isFinalNegotiation
          });
        default:
          return updateOfferStatus({
            loadNumber: notification.loadNumber,
            offerRequestId: notification.transactionId,
            resultReceivedDate: notification.receivedDate,
            updatedStatus: 'UNABLE_TO_PROCESS'
          });
      }
    }
  );

export const offersEpic = (action$, store) => combineEpics(
  fetchOffersEpic,
  refreshOffersEpic,
  refreshPopupOffersEpic,
  refreshParentOffersEpic,
  refreshOffersOnWindowEpic,
  updateOfferStatusEpic,
  updateOffersOfferStatusEpic
)(action$, store, {repo: Container.get(AvailableLoadsRepository)});

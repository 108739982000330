import { FunctionComponent } from 'react';

import { select } from 'store/selectors/state.selectors';
import { ComponentConnectorFactory } from 'store/component-connector';
import {
  Translation,
} from 'shared/components/translation/translation.component';

import './not-authorized.component.scss';
import { useSelector } from 'app/hooks/store/use-selector.hook';

interface ConnectStateProps {
  defaultCountry: CountryResponse;
}

type Props = ConnectStateProps;

export const NotAuthorizedContainer: FunctionComponent<Props> = ({
  defaultCountry,
}) => {
  const pathname = useSelector((state) => state.router.location.pathname);

  return (
    <div id="not-authorized">
		<h3>
			<Translation resource="YOU_DONT_HAVE_ACCESS_TO_THIS_PAGE" />
		</h3>
    </div>
  );
};

export const NotAuthorized = ComponentConnectorFactory<{}, ConnectStateProps>()
  .combineStateSelectors(select.app.defaultCountry)
  .connect(NotAuthorizedContainer);

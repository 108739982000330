import { AnyAction, combineReducers } from 'redux';
import { LOCATION_CHANGE } from 'connected-react-router';

import * as a from 'shared/find-loads/redux/find-loads.actions';
import { OfferStatusUpdateParameter } from 'shared/find-loads/redux/find-loads.actions';
import { createPaginationReducer } from 'shared/components/pagination/pagination.reducer';
import { searchHistory, SearchHistoryState } from 'pages/find-loads/search-history/search-history.reducers';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { AvailableLoadSearchType } from 'shared/enums/available-loads/search-type.enum';
import { getMicrosoft } from 'providers/microsoft.provider';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { LoadSummaryCarrierOffer } from 'shared/models/loads/load-summaries/load-summary-carrier-offer';
import { CarrierBook } from 'shared/models/loads/load-books/carrier-book.model';
import AvailableLoadOfferStatus from 'shared/enums/available-load-offer-status';
import { OfferType } from 'shared/enums/offer-type.enum';
import AvailableLoadOfferRejectionReason from '@shared/enums/available-load-offer-rejection-reason';

export interface FindLoadsState {
  searchType: AvailableLoadSearchType;
  results: FindLoadsResultsState;
  error: APIErrorResponse;
  searchHistory: SearchHistoryState;
  searchCriteria?: AvailableLoadSearchCriteriaJSON;
  isMicrosoftLoaded: boolean;
  searchCorrelationId?: string;
}

export interface FindLoadsResultsState {
  loads: AvailableLoadSummary[];
  offers: LoadSummaryCarrierOffer[];
  carrierBooks: CarrierBook[];
  failedBooks: number[];
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
  performedSuggestedLoadSearch: boolean;
  carrierValidationResults?: {};
}

export const searchCriteria = (state = null, action: AnyAction) => {
  switch (action.type) {
    case a.SET_SEARCH_CRITERIA:
      return action.criteria;
    case a.UNSET_SEARCH_CRITERIA:
      return null;
    default:
      return state;
  }
};

export const loads = (state = null, action) => {
  switch (action.type) {
    case a.SEARCH_AVAILABLE_LOADS_SUCCESS:
      return action.payload.results;
    case a.RESET_AVAILABLE_LOADS:
    case a.UNSET_SEARCH_CRITERIA:
      return null;
    default:
      return state;
  }
};

export const carrierBooks = (state = [], action) => {
  switch (action.type) {
    case a.STORE_NEW_CARRIER_BOOK: {
      return [...state, action.payload];
    }
    default:
      return state;
  }
};

export const failedBooks = (state = [], action) => {
  switch (action.type) {
    case a.STORE_NEW_FAILED_BOOK: {
      return [...state, action.payload];
    }
    case a.CLEAR_FAILED_BOOK: {
      return [];
    }
    default:
      return state;
  }
};

export const carrierValidationResults = (state = {}, action) => {
  switch (action.type) {
    case a.STORE_CARRIER_VALIDATION_RESULT: {
      let localState = { ...state };
      localState[action.payload.loadNumber] = action.payload.result;
      return localState;
    }
    //clear on new search
    case a.SEARCH_AVAILABLE_LOADS_SUCCESS: {
      return {};
    }
    default:
      return state;
  }
}

export const offers = (state = null, action) => {

  switch (action.type) {
    case a.SEARCH_AVAILABLE_LOADS_SUCCESS:
      const availableLoadSummaries: AvailableLoadSummary[] = action.payload.results;
      const loadSummaryCarrierOffers: LoadSummaryCarrierOffer[] = [];
      for (let i = 0; i < availableLoadSummaries?.length; ++i) {
        const load = availableLoadSummaries[i];
        for (let j = 0; j < load?.carrierOffers?.length; ++j) {
          const carrierOffer = load.carrierOffers[j];
          if (carrierOffer) {
            carrierOffer.loadNumber = load.number;
            loadSummaryCarrierOffers.push(carrierOffer);
          }
        }
      }
      return loadSummaryCarrierOffers;
    case a.RESET_AVAILABLE_LOADS:
    case a.UNSET_SEARCH_CRITERIA:
      return null;
    case a.UPDATE_OFFER_STATUS: {
      if (state == null) {
        return null;
      }
      const updatedState = [...state as LoadSummaryCarrierOffer[]];
      const updateParameter = action.payload as OfferStatusUpdateParameter;
      const index = updatedState.findIndex(x => x.loadNumber === updateParameter.loadNumber);

      if (index < 0) {
        console.debug('index for offer was < 0');
        return state;
      }

      if (!updatedState?.[index]?.latestOffer || !updateParameter) {
        // An element was null or undefined in the chain. No further null or undefined checks should be necessary
        return state;
      }

      // If the offer statu was already set to unable to process then do not update it.
      // Any further updates should be ignored.
      const ignoredStates = ['NOT_CONSIDERED', 'UNABLE_TO_PROCESS', 'MAXIMUM_NUMBER_OF_OFFERS_SUBMITTED', 'EXPIRED'];
      if (ignoredStates.indexOf(updatedState[index].latestOffer.offerStatus) > -1) {
        console.trace('event matched an ignore pattern');
        return state;
      }

      try {
        updatedState[index] = { ...updatedState[index], } as LoadSummaryCarrierOffer;
        // TODO: const { updatedStatus, offerRequestId, offerId, resultReceivedDate } = updateParameter;
        updatedState[index].latestOffer.offerStatus = updateParameter.updatedStatus;
        updatedState[index].latestOffer.offerRequestId = updateParameter.offerRequestId;
        updatedState[index].latestOffer.offerId = updateParameter.offerId;
        const hasReceivedDate = updateParameter?.resultReceivedDate != null;

        updatedState[index].latestOffer.isFinalNegotiation = updateParameter.isFinalNegotiation;

        if (hasReceivedDate && updateParameter.updatedStatus === 'ACCEPTED') {
          updatedState[index].latestOffer.acceptedByDataScience = true;
          updatedState[index].latestOffer.createdDateTime =
            updateParameter.resultReceivedDate?.toISOString();
        }

        if (hasReceivedDate && updateParameter.updatedStatus === 'COUNTERED') {
          updatedState[index].latestOffer.offerStatus = AvailableLoadOfferStatus.OPEN;
          updatedState[index].latestOffer.createdDateTime = updateParameter.resultReceivedDate?.toISOString();
          updatedState[index].latestOffer.price = updateParameter.price;
          updatedState[index].latestOffer.currencyCode = updateParameter.currencyCode;
          updatedState[index].latestOffer.offerType = OfferType.Load;
        }

        if (hasReceivedDate && updateParameter.updatedStatus === 'REJECTED') {
          if (updateParameter.rejectionReason?.toUpperCase() === 'CARRIERVALIDATION') {
            updatedState[index].latestOffer.rejectionReason = AvailableLoadOfferRejectionReason.CARRIER_VALIDATION;
          } else {
            updatedState[index].latestOffer.rejectionReason = updateParameter.rejectionReason;
          }
        }

      } catch (err) {
        console.error(err);
        console.trace('Checking state after error');
        console.trace(updatedState);
        return state;
      }

      return updatedState;

    }
    case a.ACCEPT_COUNTERED_OFFER: {
      const updatedState = [...state as LoadSummaryCarrierOffer[]];
      const index = updatedState.findIndex(x => x.loadNumber === action.payload);
      updatedState[index] = { ...updatedState[index] } as LoadSummaryCarrierOffer;

      if (!!updatedState[index]?.latestOffer) {
        updatedState[index].latestOffer.offerStatus = 'ACCEPTED'
      } else {
        console.debug('Status \'ACCEPTED\' cannot be assigned to null/undefined latestOffer');
      }

      return updatedState;
    }
    case a.STORE_NEW_OFFER:
      const offer = action.payload as LoadSummaryCarrierOffer;
      const updatedState = [...state as LoadSummaryCarrierOffer[]];
      const index = updatedState.findIndex(x => x.loadNumber === offer.loadNumber);

      if (index < 0) {
        return [...state, offer];
      }
      else {
        updatedState[index] = offer;
        return updatedState;
      }

    case a.STORE_RELOAD_OFFERS: {
      const updatedState = [...state as LoadSummaryCarrierOffer[]];

      for (let i = 0; i < action.payload?.length; ++i) {
        const carrierOffer = action.payload[i];
        const index = updatedState.findIndex(x => x.loadNumber === carrierOffer.loadNumber);
        if (index >= 0) {
          updatedState[index] = { ...updatedState[index] } as LoadSummaryCarrierOffer;
          updatedState[index].latestOffer = carrierOffer.latestOffer;
        } else {
          updatedState.push(carrierOffer);
        }
      }
      return updatedState;
    }
    default:
      return state;
  }
};

export const performedSuggestedLoadSearch = (state = false, action) => {
  switch (action.type) {
    case a.SEARCH_AVAILABLE_LOADS_SUCCESS:
      return action.payload.performedSuggestedLoadSearch ?? false;
    default:
      return state;
  }
};

export const searchType = (state = AvailableLoadSearchType.SINGLE, action) => {
  switch (action.type) {
    case a.SET_SEARCH_TYPE:
      return action.value;
    default:
      return state;
  }
};

export const searchCorrelationId = (state = null, action) => {
  switch (action.type) {
    case a.SET_SEARCH_CORRELATION_ID:
      return action.searchCorrelationId;
    default:
      return state;
  }
}

export const error = (state = null, action: AnyAction) => {
  switch (action.type) {
    case a.SEARCH_AVAILABLE_LOADS_FAILURE:
      return action.payload;
    case a.RESET_ERROR_MESSAGE:
    case LOCATION_CHANGE:
    case a.SEARCH_AVAILABLE_LOADS_SUCCESS:
      return null;
    default:
      return state;
  }
};

export const isMicrosoftLoaded = (state = Boolean(getMicrosoft()), action: AnyAction) => {
  switch (action.type) {
    case a.MICROSOFT_LOAD_SUCCESS:
      return action.isMicrosoftLoaded;
    default:
      return state;
  }
};

export const findLoadsReducer = combineReducers<FindLoadsState>({
  searchType,
  error,
  results: combineReducers({
    loads,
    offers,
    carrierBooks: carrierBooks,
    failedBooks: failedBooks,
    pagination: createPaginationReducer('find-loads-results'),
    performedSuggestedLoadSearch: performedSuggestedLoadSearch,
    carrierValidationResults: carrierValidationResults
  }),
  searchHistory,
  searchCriteria,
  isMicrosoftLoaded,
  searchCorrelationId
});

import { FETCH_CASH_ADVANCE_OPTIONS_SUCCESS } from './cash-advance-options.actions';

export const cashAdvanceOptionsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CASH_ADVANCE_OPTIONS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

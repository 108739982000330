import { combineReducers } from 'redux';

import { createStructureReducer } from 'app/forms/structure/form-structure.reducers';
import { WAYBILL_FORM, WAYBILL_FORM_STRUCTURE } from './waybill.form';
import { FormGroupStructure } from 'app/forms/structure/form-group.structure';

export const waybill = combineReducers({
  formConfig: createStructureReducer(WAYBILL_FORM_STRUCTURE, WAYBILL_FORM)
});

export interface WaybillState {
  formConfig: FormGroupStructure;
}

import React from 'react';

import { useModal } from 'app/hooks/use-modal.hook';
import { Button } from 'shared/components/elements/elements.components';
import { Modal } from 'shared/components/modal/modal.component';
import { Translation } from 'shared/components/translation/translation.component';

import './not-implemented.component.scss';

interface OwnProps {
  isModal?: boolean;
  modalName?: string;
  showLink?: boolean;
}

type Props = OwnProps;

export const NotImplemented = ({isModal, modalName, showLink}: Props) => {
  const [, hide] = useModal(modalName || 'not-implemented');

  return (
    <React.Fragment>
      {isModal
        ? (
          <Modal
            name={modalName || 'not-implemented'}
            header="COMING_SOON"
            footer={<Button type="button" btnPrimary onClick={hide} resource="CLOSE"/>}
          >
            <div className="chr-not-implemented space-outer-bottom-lg">
              <p className="text-center space-outer-top-md space-outer-bottom-md">
                <Translation resource="WE_RE_STILL_WORKING_ON_ADDING_THIS_FEATURE_TO_THE_"/>
              </p>
            </div>
          </Modal>
        )
        : (
          <div className="chr-not-implemented space-outer-bottom-lg">
            <div className="notice ">
              <h2><Translation resource="COMING_SOON"/></h2>
            </div>
            <p className="text-center space-outer-top-sm space-outer-bottom-md">
              <Translation resource="WE_RE_STILL_WORKING_ON_ADDING_THIS_FEATURE_TO_THE_"/>
            </p>
          </div>
        )
      }
    </React.Fragment>
  );
};

import { useReduceBrazeCard } from '@app/hooks/use-reduce-braze-card';
import { AppRoute } from '@app/routesEnum';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Drawer, Typography } from '@mui/material';
import { PREFERENCES_SETTING_SECTION } from 'features/account-settings';
import { AnchorButton } from '@shared/components/elements/button.component';
import { Translation } from '@shared/components/translation/translation.component';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { useMemo } from 'react';
import { history } from 'store';
import { useBrazeContext } from './initialization';
import { NotificationContentCard } from './notification-content-card';
import { NotificationEmptyState } from './notification-empty-state';
import { CustomContentCardData } from './types';

import './notification-center.scss';

export const NOTIFICATION_FEED = 'notification_center';

interface IProps {
  open?: boolean;
  onClose: () => void;
}

export const NotificationCenterFeed: React.FC<IProps> = ({ open = false, onClose }) => {
  const { contentCards, handleCardClick, handleCardImpression, requestFeedRefresh, handleLogContentCardImpressions } = useBrazeContext();

  const { countCards, validCards } = useReduceBrazeCard(contentCards);

  const { enableMarkAllAsReadNotifications } = useFlags();

  const pageContentCards = useMemo(() => {
    const currentDate = moment();
    const groupedCards = [];
    (contentCards ?? [])
      .reduce((cards: CustomContentCardData[], each: CustomContentCardData) => {
        const isDuplicate = cards.some(({ extras }) => !!extras?.card_id && extras?.card_id === each.extras?.card_id);
        const isValidCard = !isDuplicate && each.extras?.page_name === NOTIFICATION_FEED;
        if (isValidCard) {
          return [...cards, each];
        }
        return cards;
      }, [])
      .sort((a, b) => Number(b.created) - Number(a.created))
      .forEach(card => {

        const format = currentDate.isSame(card.created, 'day') ? '[TODAY]' : 'MMMM Do'
        const monthYear = moment(card.created, 'DD/MM/YYYY HH:mm:ss').format(format);

        if (!groupedCards[monthYear]) {
          groupedCards[monthYear] = [];
        }

        groupedCards[monthYear].push(card);
      });
    return groupedCards;
  }, [contentCards]);

  const triggerClickEvent = (card: CustomContentCardData) => {
    handleCardImpression(card);
    requestFeedRefresh();
  };

  const triggerCTAEvent = (card: CustomContentCardData) => {
    handleCardClick(card);
    requestFeedRefresh();
  };

  const triggerImpressionEvent = (cards: CustomContentCardData[]) => {
    handleLogContentCardImpressions(cards);
    requestFeedRefresh();
  };

  const goToSetting = () => {
    onClose()
    history.push(`${AppRoute.ACCOUNT_SETTINGS}/#/${PREFERENCES_SETTING_SECTION}`)
  };

  return (
    <Drawer
      elevation={16}
      anchor="right"
      PaperProps={{
        sx: {
          top: 60,
          height: 'calc(100% - 60px)',
        },
      }}
      open={open}
      onClose={onClose}
      BackdropProps={{ invisible: true }}
      disableScrollLock={true}
    >
      <Box role="presentation" className="notification-center-content">
        <Box sx={{ padding: '24px', display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span className="header">
              <Translation resource="NOTIFICATIONS" />
            </span>
            <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
              <AnchorButton
                onClick={goToSetting}
                btnClear
                className="close-btn"
                track
                id="btn-notification-gear"
              >
                <SettingsIcon />
              </AnchorButton>
              <AnchorButton onClick={onClose} btnClear >
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                    fill="#111112"
                  />
                </svg>
              </AnchorButton>
            </Box>
          </Box>
          {
            countCards > 0 && enableMarkAllAsReadNotifications && (
              <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '16px' }}>
                <AnchorButton id={'mark-all-as-read'} track btnClear onClick={() => triggerImpressionEvent(validCards)}>
                  <Translation resource='MARK_ALL_AS_READ' replacements={[countCards]} />
                </AnchorButton>
              </Box>
            )
          }
          {Object.keys(pageContentCards).length === 0 ? (
            <NotificationEmptyState />
          ) : (
            Object.keys(pageContentCards).map(formattedDate => {
              let parts = formattedDate.split(" ");
              let month = parts[0];
              let day = parts[1];
              let uppercasedMonth = month.toUpperCase();
              let resultString = day ? uppercasedMonth + " " + day : uppercasedMonth;
              
              return (
                <Box key={formattedDate}>
                  <Typography sx={{ margin: '16px 0px 0px 0px', color: '#222222' }}>
                    {resultString}
                  </Typography>
                  {pageContentCards[formattedDate].map(notification => (
                    <NotificationContentCard
                      key={notification.id}
                      card={notification}
                      handleCardClick={triggerClickEvent}
                      handleCTAClick={triggerCTAEvent}
                    />
                  ))}
                </Box>
              );
            })
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

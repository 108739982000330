import React from 'react';
import { Action, AnyAction } from 'redux';
import { combineEpics, StateObservable } from 'redux-observable';
import { Container } from 'typedi';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

import * as a from '../search/my-shipments-search.actions';
import { GlobalShipmentsRepository } from 'app/repositories/global-shipments.repository';
import { Translation } from 'shared/components/translation/translation.component';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';

interface EpicDependencies {
  repo?: GlobalShipmentsRepository;
}

const getSearchCriteriaFromStore = (state$: StateObservable<NavCarrierState>) => () => {
  const { search, pagination, sort } = state$.value.myShipments.search.params;
  return {
    ...search,
    ...pagination,
    SortCriteria: [sort]
  };
};

type MyShipmentsEpic<OutputAction extends Action> = NavCarrierEpic<OutputAction, EpicDependencies>;

// (SET_SHIPMENT_SEARCH_PARAMS, SET_SHIPMENT_SORT_PARAMS, SET_SHIPMENT_PAGINATION_PARAMS) -> MY_SHIPMENTS_SEARCH_START
export const updateMyShipmentsSearchParamsEpic: MyShipmentsEpic<AnyAction> = (action$, state$) =>
  action$.ofType(a.SET_SHIPMENT_SEARCH_PARAMS, a.SET_SHIPMENT_SORT_PARAMS, a.SET_SHIPMENT_PAGINATION_PARAMS)
    .map(getSearchCriteriaFromStore(state$))
    .map(a.searchMyShipments);

// MY_SHIPMENTS_SEARCH_START --> MY_SHIPMENTS_SEARCH_SUCCESS or MY_SHIPMENTS_SEARCH_FAILURE
export const searchMyShipmentsEpic: MyShipmentsEpic<AnyAction> = (action$, state$, { repo }) =>
  action$.ofType(a.MY_SHIPMENTS_SEARCH_START)
    .mergeMap(({ criteria }) =>
      repo.search(criteria)
        .debounceTime(150)
        .map((results) => a.myShipmentsSearchSuccess(results))
        .catch((res: AjaxError) => Observable.of(a.myShipmentsSearchFailure(res)))
    );

export const myShipmentsSearchSuccessEpic: MyShipmentsEpic<AnyAction> = (action$, state$) =>
  action$.ofType(a.MY_SHIPMENTS_SEARCH_SUCCESS)
    .map(({ payload }) => {
      const { pendingSearchValues } = state$.value.myShipments.search;
      if (payload && payload.totalCount) {
        // has results
        return [
          a.setCurrentSearchValues(pendingSearchValues), // set the successful search form values for the search accordion header
          a.resetPendingSearchValues(), // no longer pending!
          a.resetSearchError() // reset any errors
        ];
      }

      // does not have results :(
      return [
        a.resetCurrentSearchValues(), // updates search header
        a.resetPendingSearchValues(),
        a.setSearchError(React.createElement(Translation, { resource: 'THERE_ARE_NO_LOADS2' }))
      ];
    })
    .mergeMap(actions => Observable.from(actions));

export const myShipmentsSearchFailureEpic: MyShipmentsEpic<AnyAction> = action$ =>
  action$.ofType(a.MY_SHIPMENTS_SEARCH_FAILURE)
    .map(({ error }) =>
      (error instanceof AjaxError)
        ? a.setSearchError((error.response && (error.response.message || error.response)) || error.message)
        : a.setSearchError(React.createElement(Translation, { resource: 'CANNOT_PROCESS_REQUEST' }))
    );

export const myShipmentsEpic = (action$, state$) => combineEpics(
  searchMyShipmentsEpic,
  updateMyShipmentsSearchParamsEpic,
  myShipmentsSearchSuccessEpic,
  myShipmentsSearchFailureEpic
)(action$, state$, {
  repo: Container.get(GlobalShipmentsRepository)
});

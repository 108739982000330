import { Container } from 'typedi';

if (window?.opener) {
  try {
    const api = Container.get<any>('parentInternalAPI');
    if (api?.sessionStorage) {
      // bootstrap parents session
      Container.set('parentSessionStorage', api.sessionStorage);
      delete api.sessionStorage;
    }
  } catch (e) {
    // do nothing.  This will happen whenever any page is loaded if it is IN the parent window.
  }
}

import React from 'react';
import { Action, AnyAction } from 'redux';
import { combineEpics, StateObservable } from 'redux-observable';
import { Container } from 'typedi';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

import * as a from '../search/my-loads-search.actions';
import { InterimRepository } from 'app/repositories/interim.repository';
import { Translation } from 'shared/components/translation/translation.component';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { GatewayRepository } from 'app/repositories/gateway.repository';

interface EpicDependencies {
  interimRepository?: InterimRepository;
  gatewayRepository?: GatewayRepository;
}

const getSearchCriteriaFromStore = (state$: StateObservable<NavCarrierState>) => () => {
  const { search, pagination, sort, defaultSorting } = state$.value.myLoads.search.params;
  return {
    ...search,
    ...pagination,
    ...sort,
    defaultSorting
  };
};

type MyLoadsEpic<OutputAction extends Action> = NavCarrierEpic<OutputAction, EpicDependencies>;

// (SET_SEARCH_PARAMS, SET_SORT_PARAMS, SET_PAGINATION_PARAMS) -> MY_LOADS_SEARCH_START
export const updateMyLoadsSearchParamsEpic: MyLoadsEpic<AnyAction> = (action$, state$) =>
  action$.ofType(a.SET_SEARCH_PARAMS, a.SET_SORT_PARAMS, a.SET_PAGINATION_PARAMS)
    .map(getSearchCriteriaFromStore(state$))
    .map(a.searchMyLoads);

// MY_LOADS_SEARCH_START --> MY_LOADS_SEARCH_SUCCESS or MY_LOADS_SEARCH_FAILURE
export const searchMyLoadsEpic: MyLoadsEpic<AnyAction> = (action$, state$, { interimRepository, gatewayRepository }) =>
  action$.ofType(a.MY_LOADS_SEARCH_START)
    .mergeMap(({ criteria }) => {
      return gatewayRepository.searchLoads(criteria)
        .debounceTime(150)
        .map((results) => a.myLoadsSearchSuccess(results))
        .catch((res: AjaxError) => Observable.of(a.myLoadsSearchFailure(res)));
    }
    );

export const myLoadsSearchSuccessEpic: MyLoadsEpic<AnyAction> = (action$, state$) =>
  action$.ofType(a.MY_LOADS_SEARCH_SUCCESS)
    .map(({ payload }) => {
      const { pendingSearchValues } = state$.value.myLoads.search;
      if (payload?.totalRecords) {
        // has results
        return [
          a.setCurrentSearchValues(pendingSearchValues), // set the successful search form values for the search accordion header
          a.resetPendingSearchValues(), // no longer pending!
          a.resetSearchError() // reset any errors
        ];
      }

      // does not have results :(
      return [
        a.resetCurrentSearchValues(), // updates search header
        a.resetPendingSearchValues(),
      ];
    })
    .mergeMap(actions => Observable.from(actions));

export const myLoadsSearchFailureEpic: MyLoadsEpic<AnyAction> = action$ =>
  action$.ofType(a.MY_LOADS_SEARCH_FAILURE)
    .map(({ error }) =>
      (error instanceof AjaxError)
        ? a.setSearchError((error.response && (error.response.message || error.response)) || error.message)
        : a.setSearchError(React.createElement(Translation, { resource: 'CANNOT_PROCESS_REQUEST' }))
    );

export const myLoadsEpic = (action$, state$) => combineEpics(
  searchMyLoadsEpic,
  updateMyLoadsSearchParamsEpic,
  myLoadsSearchSuccessEpic,
  myLoadsSearchFailureEpic
)(action$, state$, {
  interimRepository: Container.get(InterimRepository),
  gatewayRepository: Container.get(GatewayRepository)
});

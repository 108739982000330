import { Container } from 'typedi';

import { Store } from 'store/index';
import { epic$ } from 'store/epics/root.epics';
import { loadDetailsReducers } from './load-details.reducer';
import { loadDetailsEpic } from './load-details.epics';

const store = Container.get(Store);

store.reducerManager.add('loadDetails', loadDetailsReducers);
store.dispatch({type: 'init-loadDetails'});

epic$.next(loadDetailsEpic);

import { useEffect, useState } from 'react';
import branch, { InitOptions, IdentityCallbackData } from 'branch-sdk';
import { useStoredUser } from '@app/hooks/store/use-stored-user.hook';

/**
 * BranchIO Initializer
 */
export const BranchProvider = () => {
 const [didRender, toggleRendered] = useState(false);
 const user = useStoredUser();

 // Set our FS user Identity
 useEffect(() => {
  if (user?.userId != null) {
   branch.setIdentity(user?.userId.toString(), (err: string, branchUser: IdentityCallbackData) => {
    if (err != null) {
     console.error(`Unable to set BranchIO Identity: ${err}`);
    }
   });
  }
 }, [user]);

 useEffect(() => {
  if (!didRender) {
   toggleRendered(true);
   branch.init(appConstants.branchApiKey, {} as InitOptions);
  }
 }, [didRender]);

 return <></>;
};

import { Container } from 'typedi';

import { Store } from 'store';
import { epic$ } from 'store/epics/root.epics';
import { select } from 'store/selectors/state.selectors';
import { offersReducer } from 'shared/offers/redux/offers.reducers';
import { offersSelectors } from 'shared/offers/redux/offers.selectors';
import { offersEpic } from 'shared/offers/redux/offers.epics';

const store = Container.get(Store);

store.reducerManager.add('offers', offersReducer);
store.dispatch({type: 'init-offers'});

Object.defineProperty(select, 'offers', {value: offersSelectors});

epic$.next(offersEpic);

import React from 'react';
import { FunctionComponent } from 'react';
import { Route } from 'react-router';
import { Location } from 'history';

import { select } from 'store/selectors/state.selectors';
import { Login } from 'pages/login/login.component';
import { ComponentConnectorFactory } from 'store/component-connector';
import { User } from 'shared/models/user.model';
import { useAuthorization } from 'features/permissions';
import { AppRoute } from 'app/routesEnum';
import { NotAuthorized } from 'shared/components/not-authorized/not-authorized.component';
import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';
import { useSelector } from 'app/hooks/store/use-selector.hook';

interface OwnProps {
  path: AppRoute;
  render?: (innerProps) => JSX.Element;
  component?: React.ComponentType<any>;
  exact?: boolean;
}

interface ConnectStateProps {
  isAuthenticated: boolean;
  location: Location;
  user: User;
  carrier: CarrierDetail;
  isACSUser: boolean;
  children: React.ReactNode;
}

type Props = OwnProps & ConnectStateProps;

export const PrivateRouteContainer: FunctionComponent<Props> = ({isAuthenticated, render, user, carrier, component, children, isACSUser, ...props}) => {
  const isAuthorized = useAuthorization(props.path);
  const activeMembership = useSelector(s => s.userManagement.activeMembership);

  return (
   <Route
    {...props}
    render={innerProps =>
     !isAuthenticated ? (
      <Login />
     ) : !isAuthorized ? (
      <NotAuthorized />
     ) : render ? (
      render(innerProps)
     ) : component ? (
      React.createElement(component)
     ) : (
      children
     )
    }
   />
  );
};

export const PrivateRoute = ComponentConnectorFactory<OwnProps, ConnectStateProps>()
  .combineStateSelectors(
    select.router.location, // ensures route re-renders on location change
    select.auth.isAuthenticated,
    select.auth.user,
    select.auth.carrier,
    select.auth.isACSUser
  )
  .connect(PrivateRouteContainer);

import cloneDeep from 'lodash.clonedeep';
import mapValues from 'lodash.mapvalues';

import * as a from 'app/forms/structure/form-structure.actions';
import { FormStructureType } from 'app/forms/structure/form-structure-types.enum';
import { FormFieldStructure } from 'app/forms/structure/form-field.structure';
import { FormChildStructure, FormGroupStructure } from 'app/forms/structure/form-group.structure';

export const createStructureReducer = (internalInitialStructure, formKey?: string) => (state = cloneDeep(internalInitialStructure), action) => {
  if (state === internalInitialStructure && formKey) {
    internalInitialStructure.key = formKey;
  }

  if (formKey && action.formKey !== formKey) {
    return state;
  }
  const newState = cloneDeep(state);

  let pathMap = [];
  let key;
  let parent, child;
  if (action.path) {
    pathMap = action.path.split('.');
    key = pathMap.pop();
    parent = pathMap.reduce((group: FormGroupStructure, pathKey) => group.children[pathKey], newState);
  }
  switch (action.type) {
    case a.REMOVE_CHILD:
      delete parent.children[key];
      return newState;

    case a.ENABLE:
      if (!parent && !action.path) {
        return { ...newState, disabled: false };
      }

      child = parent.children[key];
      parent.children[key] = { ...child, disabled: false };
      return newState;

    case a.DISABLE:
      if (!parent && !action.path) {
        return { ...newState, disabled: true };
      }

      child = parent.children[key];
      parent.children[key] = { ...child, disabled: true };
      return newState;

    case a.ADD_CHILD:
      parent.children = {
        ...parent.children,
        [key]: action.child
      };
      return newState;

    case a.UPDATE_FIELD:
      const field = parent.children[key];
      if (field && field.type === FormStructureType.Field) {
        parent.children[key] = { ...field, ...action.field };
      }
      return newState;

    case a.PATCH_GROUP_VALUES:
      if (!parent && !action.path) {
        if (newState.type === FormStructureType.Group) {
          return patchChildValuesOfGroup(newState, action.values);
        } else if (newState.type === FormStructureType.Array) {
          return patchChildValuesOfArray(newState, action.values);
        }
        return state;
      }
      child = parent.children[key];
      if (child && child.type === FormStructureType.Group) {
        parent.children[key] = patchChildValuesOfGroup(child, action.values);
      } else if (child && child.type === FormStructureType.Array) {
        parent.children[key] = patchChildValuesOfArray(child, action.values);
      }

      return newState;

    case a.SET_GROUP_VALUES:
      if (!parent && !key) {
        if (newState.type === FormStructureType.Group) {
          return setChildValuesOfGroup(newState, action.values);
        } else if (newState.type === FormStructureType.Array) {
          return setChildValuesOfArray(newState, action.values);
        }
        return state;
      }

      child = parent.children[key];
      if (child && child.type === FormStructureType.Group) {
        parent.children[key] = setChildValuesOfGroup(child, action.values);
      } else if (child && child.type === FormStructureType.Array) {
        parent.children[key] = setChildValuesOfArray(child, action.values);
      }
      return newState;

    case a.APPLY_NEW_STRUCTURE:
      return action.structure;

    case a.SET_API_ERRORS:
      return { ...newState, serverErrors: action.errors };

    case a.CLEAR_API_ERRORS:
      return { ...newState, serverErrors: [] };

    case a.RESET_FORM:
      return internalInitialStructure;
    case a.RESET_FORM_GROUP:
      if (parent && key && parent.children[key]) {
        parent.children = {
          ...parent.children,
          [key]: resetItem(parent.children[key])
        };

        // case when parent is an array?

        return newState;
      }
      return state;
    case a.FORCE_VALIDATION:
      return touchItem(newState);
    default:
      return state;
  }
};

const resetItem = (item: FormChildStructure) => (
  (item.type === FormStructureType.Field && resetField(item)) ||
  (item.type === FormStructureType.Group && resetGroup(item as FormGroupStructure)) ||
  (item.type === FormStructureType.Array && resetArray(item as FormArrayStructure))
);

const resetGroup = (group: FormGroupStructure) => {
  return {
    ...group,
    children: Object.entries(group.children).reduce((newChildren, [key, child]) => {
      return {
        ...newChildren,
        [key]: resetItem(child)
      };
    }, {})
  };
};

const resetArray = (formArray: FormArrayStructure) => {
  return {
    ...formArray,
    children: formArray.children.reduce((newChildren, child, index) => {
      return [
        ...newChildren.slice(0, index),
        resetItem(child),
        ...newChildren.slice(index + 1)
      ];
    }, [])
  };
};

const resetField = (field: FormFieldStructure) => ({ ...field, value: field.initialValue, dirty: false, touched: false });

const patchChildValuesOfGroup = (parent, values) => {
  return {
    ...parent,
    children: Object.entries(values).reduce((newChildren, [childKey, value]) => {
      if (parent.children[childKey]) { // make sure child exists
        return {
          ...newChildren,
          [childKey]: setSingleChildValue(parent.children[childKey], value)
        };
      }
      return newChildren; // skip non-existent children;
    }, parent.children)
  };
};

const setChildValuesOfGroup = (parent, values) => {
  return {
    ...parent,
    children: Object.entries(parent.children).reduce((newChildren, [childKey, child]) => ({
      ...newChildren,
      [childKey]: setSingleChildValue(child, values[childKey])
    }), parent.children)
  };
};

const patchChildValuesOfArray = (parent, values: FormChildStructure[] = []) => {
  return {
    ...parent,
    children: values.reduce((newChildren, value, index) => [
      ...newChildren.slice(0, index),
      setSingleChildValue(parent.children[index], value),
      ...newChildren.slice(index + 1)
    ], parent.children)
  };
};

const setChildValuesOfArray = (parent: FormArrayStructure, values) => {
  return {
    ...parent,
    children: parent.children.reduce((newChildren, child, index) => [
      ...newChildren.slice(0, index),
      setSingleChildValue(child, values[index]),
      ...newChildren.slice(index + 1)
    ], parent.children)
  };
};

const setSingleChildValue = (child, childValue) => {
  if (child) {
    if (child.type === FormStructureType.Field) {
      child.value = childValue;
      return {
        ...child,
        value: childValue,
        touched: true,
        dirty: true
      };
    } else if (child.type === FormStructureType.Group) {
      return patchChildValuesOfGroup(child, childValue);
    } else if (child.type === FormStructureType.Array) {
      return patchChildValuesOfArray(child, childValue);
    }
  }
  return child;
};

const touchItem = (field) => {
  if (!field) {
    return field;
  }

  switch (field.type) {
    case FormStructureType.Field:
      return { ...field, touched: true };
    case FormStructureType.Group:
      return { ...field, children: mapValues(field.children, touchItem) };
    case FormStructureType.Array:
      return { ...field, children: field.children.map(touchItem) };
    default:
      return field;
  }
};

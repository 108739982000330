import { AnyAction } from 'redux';

import * as a from 'shared/offers/redux/offers.actions';
import { Offer } from 'shared/models/offers/offer.model';
import { OfferStatus } from 'shared/enums/offer-status.enum';
import { OfferStatusUpdateParameter } from 'shared/offers/redux/offers.actions';
import { OfferType } from '../../enums/offer-type.enum';
import AvailableLoadOfferRejectionReason from '../../enums/available-load-offer-rejection-reason';

export const offersReducer = (state = [] as Offer[], action: AnyAction) => {
  switch (action.type) {
    case a.FETCH_OFFERS_SUCCESS:
      return action.payload;
    case a.STORE_NEW_OFFER:
      return [...state, ...action.payload];
    case a.UPDATE_OFFER_STATUS_FROM_SIGNALR:
      if (state == null) {
        return null;
      }
      const updatedState = [...state];
      const parameter = action.payload as OfferStatusUpdateParameter;

      const hasReceivedDate = parameter?.resultReceivedDate != null;

      //find the previously submitted offer
      const index = updatedState.findIndex(c => offerMatchesPayload(c, parameter));

      if (index < 0) {
        return state;
      }
      if (parameter.updatedStatus === OfferStatus.COUNTERED && hasReceivedDate) {
          //Update new truck offer created to Rejected
          updatedState[index].offerId = -1;
          updatedState[index].displayStatus = OfferStatus.REJECTED;
          updatedState[index].rejectionReason = AvailableLoadOfferRejectionReason.PRICE

          //Create new Load offfer as counter offer
          const anyLoadOffer = updatedState[index];
          const newOfferJson: any = {
            ...anyLoadOffer,
            offerId: parameter.offerId,
            offerRequestId: parameter.offerRequestId,
            displayStatus: OfferStatus.OPEN,
            rejectionReason: null,
            offerPrice: parameter.price,
            currencyCode: parameter.currencyCode,
            offerType: OfferType.Load,
            loadNumber: parameter.loadNumber,
            enteredDate: parameter.resultReceivedDate,
            acceptedByDataScience: null,
            isShipmentOfferable: false,
            isShipmentCounterOfferable: parameter.isFinalNegotiation ? false : true,
          };
          updatedState.push(new Offer(newOfferJson));
      } else {
        if (parameter.updatedStatus === OfferStatus.ACCEPTED && hasReceivedDate) {
          updatedState[index].acceptedByDataScience = true;
          updatedState[index].offerPrice = parameter.price;
          updatedState[index].currencyCode = parameter.currencyCode;
          updatedState[index].enteredDate = parameter.resultReceivedDate;
          updatedState[index].enteredDateAdjustedTimeZone = parameter.resultReceivedDate;
        }
        if (parameter.updatedStatus === OfferStatus.REJECTED) {
          updatedState[index].isShipmentOfferable = parameter.isFinalNegotiation ? false : true;
          updatedState[index].isShipmentCounterOfferable = false;
          if (parameter.rejectionReason?.toUpperCase() === 'CARRIERVALIDATION') {
            updatedState[index].rejectionReason = AvailableLoadOfferRejectionReason.CARRIER_VALIDATION;
          } else {
            updatedState[index].rejectionReason = parameter.rejectionReason;
          }
        }

        updatedState[index].displayStatus = parameter.updatedStatus;
        updatedState[index].offerId = parameter.offerId;
        updatedState[index].offerRequestId = parameter.offerRequestId;
      }

      updatedState
        .filter(s => s.loadNumber == parameter.loadNumber && s.offerId != parameter.offerId)
        .forEach(s => {
          s.isShipmentOfferable = false;
          s.isShipmentCounterOfferable = false;
        });

      return updatedState;
    case a.ACCEPT_COUNTERED_OFFER_FROM_OFFERS: {
      const updatedState = [...state];
      const index = updatedState.findIndex(x => x.loadNumber === action.payload.loadNumber
        && x.offerType === OfferType.Load && x.offerId === action.payload.offerId);
      if (index < 0) {
        return state;
      }
      updatedState[index].displayStatus = OfferStatus.ACCEPTED;
      return updatedState;
    }
    case a.REJECT_COUNTERED_OFFER_FROM_OFFERS: {
      const updatedState = [...state];
      const index = updatedState.findIndex(x => x.loadNumber === action.payload.loadNumber && x.offerType === OfferType.Load && x.offerId === action.payload.offerId);
      if (index < 0) {
        return state;
      }
      updatedState[index].displayStatus = OfferStatus.REJECTED;
      updatedState[index].rejectionReason = AvailableLoadOfferRejectionReason.PRICE;
      updatedState[index].isShipmentOfferable = false;

      return updatedState;
    }
    case a.UPDATE_OFFER_STATUS_ON_OFFERS_STORE: {
      if (state == null) {
        return null;
      }
      const updatedState = [...state];
      const parameter = action.payload as OfferStatusUpdateParameter;
      const index = updatedState.findIndex(c =>
        c.loadNumber == parameter.loadNumber && c.offerId == parameter.offerId);
      if (index < 0) {
        return state;
      }
      updatedState[index].displayStatus = parameter.updatedStatus;
      return updatedState;
    }
    case a.UPDATE_OFFER: {
      const updatedState = [...state as Offer[]];
      const offerId = action.payload.offerId;
      const status = action.payload.updatedStatus;
      let index: number;

      switch (status) {
        case OfferStatus.EXPIRED:
          index = updatedState.findIndex(x => x.offerId === offerId);
          if (index < 0) {
            return state;
          }
          updatedState[index].displayStatus = OfferStatus.EXPIRED;
          break;
        default:
          break;
      }
      return updatedState;
    }
    case a.UPDATE_EU_OFFER: {
      const updatedState = [...state as Offer[]];
      const offerId = action.payload.offerId;
      let index: number;
      index = updatedState.findIndex(x => x.offerId === offerId);
      if (index < 0) {
        return state;
      }
      updatedState[index].offerPrice = action.payload.offerPrice ?? updatedState[index].offerPrice;
      updatedState[index].offerNote = action.payload.offerNote ?? updatedState[index].offerNote;
      updatedState[index].enteredDate = action.payload.enteredDate ?? updatedState[index].enteredDate;

      return updatedState;
    }
    default:
      return state;
  }
};

function offerMatchesPayload(offer: Offer, payload: OfferStatusUpdateParameter): boolean {
  if (offer.offerId) {
    return false;
  }
  return offer.loadNumber === payload.loadNumber && offer.offerRequestId === payload.offerRequestId;
}

export type OffersState = Offer[];

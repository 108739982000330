import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';
import { customMappers } from 'app/legacy/custom-url-mappers';
import { BetaAppRoute, LegacyAppRoute } from 'shared/enums/routes.enum';

const getAppRouteKey = (path: string, search: string): keyof BetaAppRoute => {
  const url = path + search;
  const route = Object.entries(BetaAppRoute).find(([, value]) => url.startsWith(value));
  console.error('searching for route', url);
  if (route) {
    console.error('found', route);
    const [routeKey] = route;
    return routeKey as keyof BetaAppRoute;
  }
  console.error('not found', BetaAppRoute);
  return 'ROOT' as keyof BetaAppRoute;
};
const getLegacyRouteKey = (path: string, search: string): keyof LegacyAppRoute => {
  const url = path + search;
  const route = Object.entries(LegacyAppRoute)
    .find(([, value]: [string, string]) =>
      url.toLowerCase().startsWith(value.toLowerCase())
    );

  if (route) {
    const [routeKey] = route;
    return routeKey as keyof BetaAppRoute;
  }
  return 'ROOT' as keyof LegacyAppRoute;
};

const toLegacy = (key: keyof BetaAppRoute): LegacyAppRoute => {
  return LegacyAppRoute[key];
};
const toBeta = (key: keyof LegacyAppRoute): BetaAppRoute => {
  return BetaAppRoute[key];
};

export class URLMapper {
  private customMaps: { [key: string]: CustomMap } = customMappers;

  constructor(private carrier: CarrierDetail) { }

  toLegacy(path: string, search: string): LegacyAppRoute {
    const key = getAppRouteKey(path, search);
    if (key in this.customMaps) {
      return (this.customMaps[key] as CustomMap).toLegacy(path, search, this.carrier);
    }
    return toLegacy(key);
  }

  toBeta(path: string, search: string): BetaAppRoute {
    const key = getLegacyRouteKey(path, search);
    if (key in this.customMaps) {
      return (this.customMaps[key] as CustomMap).toBeta(path, search);
    }
    return toBeta(key);
  }
}

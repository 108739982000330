import qs from 'qs';
import { BetaAppRoute, LegacyAppRoute } from 'shared/enums/routes.enum';

const parseQueryString = (queryString: string) => qs.parse(queryString, { ignoreQueryPrefix: true });

const findLoadDetailsMapper: CustomMap = {
  toBeta: (path, search) => {
    const { LoadNumber } = parseQueryString(search);
    return (BetaAppRoute.FIND_LOAD_DETAILS + LoadNumber) as BetaAppRoute;
  },
  toLegacy: path => {
    const queryString = qs.stringify({ LoadNumber: path.split('/').pop() }, { addQueryPrefix: true });
    return (LegacyAppRoute.FIND_LOAD_DETAILS + queryString) as LegacyAppRoute;
  }
};

const myLoadsDetailMapper: CustomMap = {
  toBeta: (path, search) => {
    const { LoadNumber, BookType, Carrier } = parseQueryString(search);
    const queryString = qs.stringify({ bookType: BookType, carrierCode: Carrier }, { addQueryPrefix: true });
    // load-details/106510127?bookType=TL&carrierCode=T126776
    return (BetaAppRoute.MY_LOAD_DETAILS + `/${LoadNumber}${queryString}`) as BetaAppRoute;
  },
  toLegacy: (path, search) => {
    const queryString = qs.stringify({
      LoadNumber: path.split('/').pop(),
      Carrier: parseQueryString(search).carrierCode,
      BookType: parseQueryString(search).bookType,
    }, { addQueryPrefix: true });

    return (LegacyAppRoute.MY_LOAD_DETAILS + queryString) as LegacyAppRoute;
  }
};

const editInvoiceMapper: CustomMap = {
  toBeta: (path, search) => {
    const { id: invoiceNumber } = parseQueryString(search);
    return (BetaAppRoute.EDIT_INVOICE + invoiceNumber) as BetaAppRoute;
  },
  toLegacy: path => {
    const invoiceNumber = path.split('/').pop();
    return (LegacyAppRoute.EDIT_INVOICE + invoiceNumber) as LegacyAppRoute;
  }
};

const viewInvoiceMapper: CustomMap = {
  toBeta: (path, search): BetaAppRoute => {
    const { val: invoiceNumber } = parseQueryString(search);
    return (BetaAppRoute.VIEW_INVOICE + invoiceNumber) as BetaAppRoute;
  },
  toLegacy: path => {
    const invoiceNumber = path.split('/').pop();
    return (LegacyAppRoute.VIEW_INVOICE + qs.stringify({ val: invoiceNumber }, { addQueryPrefix: true })) as LegacyAppRoute;
  }
};

const tenderDetailsMapper: CustomMap = {
  toBeta: (path, search) => {
    const { LoadNumber, TenderSequenceNumber } = parseQueryString(search);

    return (BetaAppRoute.TENDER_DETAILS + `${LoadNumber}/${TenderSequenceNumber}`) as BetaAppRoute;
  },
  toLegacy: (path, search, carrier) => {
    const parts = path.split('/');
    const queryString = qs.stringify(
      {
        TenderSequenceNumber: parts.pop(),
        LoadNumber: parts.pop(),
        CarrierID: carrier?.carrierCode
      },
      { addQueryPrefix: true }
    );

    return (LegacyAppRoute.TENDER_DETAILS + queryString) as LegacyAppRoute;
  }
};
const binLoadMapper: CustomMap = {
  toBeta: (path: string, search: string) => {
    const { LoadNumber } = parseQueryString(search);
    return (BetaAppRoute.BIN_LOAD + `${LoadNumber}`) as BetaAppRoute;
  },
  toLegacy: (path) => {
    const parts = path.split('/');
    const queryString = qs.stringify(
      {
        LoadNumber: parts.pop(),
        CostQuoteId: parts.pop(),
        CostQuoteSequenceNumber: parts.pop(),
        CurrencyCode: parts.pop(),
        TotalCost: parts.pop()
      },
      { addQueryPrefix: true }
    );
    return (LegacyAppRoute.BIN_LOAD + queryString) as LegacyAppRoute;
  }
};
const binBatchMapper: CustomMap = {
  // bin-loads/${loadNumber}
  toBeta: () => {
    return (BetaAppRoute.BIN_BATCH) as BetaAppRoute;
  },
  toLegacy: (path) => {
    const parts = path.split('/');
    const queryString = qs.stringify(
      {
        LoadNumber: parts.pop(),
        CostQuoteId: parts.pop(),
        CostQuoteSequenceNumber: parts.pop(),
        CurrencyCode: parts.pop(),
        TotalCost: parts.pop()
      },
      { addQueryPrefix: true }
    );
    return (LegacyAppRoute.BIN_BATCH + queryString) as LegacyAppRoute;
  }
};

const shortRegistrationMapper: CustomMap = {
  toBeta: (path, search) => {
    const branchId = parseQueryString(search);
    return (BetaAppRoute.SHORT_REGISTRATION + `?_branch_match_id=${branchId._branch_match_id}`) as BetaAppRoute;
  },
  toLegacy: (path) => {
    const parts = path.split('/');
    const queryString = qs.stringify(
      {
        _branch_match_id: parts.pop()
      },
      { addQueryPrefix: true }
    );
    return (LegacyAppRoute.SHORT_REGISTRATION + queryString) as LegacyAppRoute;
  }
};

const smsSubscriptionMapper: CustomMap = {
  toBeta: (path, search) => {
    const { _branch_match_id } = parseQueryString(search);
    const queryString = qs.stringify({ _branch_match_id }, { addQueryPrefix: true });
    return (BetaAppRoute.SMS_SUBSCRIPTION + queryString) as BetaAppRoute;
  },
  toLegacy: () => {
    return LegacyAppRoute.LOGIN;
  }
};

export const customMappers: { [key: string]: CustomMap } = {
  FIND_LOAD_DETAILS: findLoadDetailsMapper,
  MY_LOAD_DETAILS: myLoadsDetailMapper,
  EDIT_INVOICE: editInvoiceMapper,
  VIEW_INVOICE: viewInvoiceMapper,
  TENDER_DETAILS: tenderDetailsMapper,
  BIN_LOAD: binLoadMapper,
  BIN_LOAD_2: binLoadMapper,
  BIN_BATCH: binBatchMapper,
  BIN_BATCH_2: binBatchMapper,
  SMS_SUBSCRIPTION: smsSubscriptionMapper,
  SHORT_REGISTRATION: shortRegistrationMapper
};

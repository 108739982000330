import { lazy } from 'react';

export * from './components';

export const LazySetUpUser = lazy(() =>
 import(/* webpackChunkName: "Invitation", webpackPrefetch: true */ 'features/set-up-user/components/SetUpUser').then(
  ({ SetUpUser }) => ({
   default: SetUpUser,
  })
 )
);
import React from 'react';
import { useLoginEvents } from '../redux/analytics-v2.selectors';
import { useAnalyticsEventMetaData } from '../hooks/use-analytics-event-metadata.hook';
import { EVENT_TYPES } from '../constants';
import { useAnalyticsTrack } from '@chr/react-analytics';
import { generateEventSequence } from '../utils/event-sequence-generator';
import { useSelector } from '@app/hooks/store/use-selector.hook';
import { APIErrorResponse } from '@app/repositories/errors/api-error-response';

const LoginEventsComponent = () => {
  const track = useAnalyticsTrack();
  const loginEvents = useLoginEvents();
  const eventMetaData = useAnalyticsEventMetaData(true);
  const loginError = useSelector(state => state.auth.errors);


  const errorMessages = {
    'E1030106C2A5': "THE_USERNAME_OR_PASSWORD_YOU_ENTERED_IS_INCORRECT",
    'E1030107C2A5': "THIS_USER_ACCOUNT_IS_TEMPORARILY_LOCKED_TO_UNLOCK_",
    'E1030105C2A5': "THIS_USER_ACCOUNT_IS_NO_LONGER_ACTIVE_FOR_MORE_INF",
    'E1030104C2A5': "THIS_USER_ACCOUNT_HAS_NOT_YET_BEEN_APPROVED",
    'limitedUser': "LIMITED_USER_ERROR_MESSAGE"
  };
  
  const getErrorKey = (err: APIErrorResponse) => {
    if(err instanceof APIErrorResponse) {
      for (const code in errorMessages) {
        if (err.includes(code)) {
          return errorMessages[code];
        }
      }
    }

    return "AN_UNKNOWN_ERROR_OCCURRED_TRYING_TO_MAKE_THE_REQUE";
  };

  const eventData = React.useMemo(() => {
    const { timeGMT, sequenceNumber: sessionEventSequenceNumber } = generateEventSequence();

    let data: KeyValuePair = {
      ...eventMetaData,
      sequence: {
        timeGMT,
        sessionEventSequenceNumber,
      }
    };

    return data;
  }, [
    eventMetaData,
    loginEvents.loginViewed,
    loginEvents.loginSubmitted,
    loginEvents.loginSuccess,
    loginEvents.loginFailure
  ]);

  React.useEffect(() => {
    if (loginEvents.loginViewed) {
      track({ event: EVENT_TYPES.LOGIN_VIEWED, ...eventData });
    }
  }, [loginEvents.loginViewed, track]);

  React.useEffect(() => {
    if (loginEvents.loginSubmitted) {
      track({ event: EVENT_TYPES.SUBMIT_LOGIN, ...eventData });
    }
  }, [loginEvents.loginSubmitted, track]);

  React.useEffect(() => {
    if (loginEvents.loginSuccess) {
      track({ event: EVENT_TYPES.LOGIN_SUCCESS, ...eventData });
    }
  }, [loginEvents.loginSuccess, track]);

  React.useEffect(() => {
    if (loginEvents.loginFailure && Boolean(loginError)) {
      track({ event: EVENT_TYPES.LOGIN_FAILURE, ...eventData, typeError: getErrorKey(loginError) });
    }
  }, [loginEvents.loginFailure, track, loginError]);

  return null;
}

export default LoginEventsComponent;

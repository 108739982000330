import { useModal } from 'app/hooks/use-modal.hook';
import { useCallback } from 'react';
import { HideModalAction, ShowModalAction } from 'shared/components/modal/modal.actions';

export const useFeedbackModal = (name = 'feedback'): [() => ShowModalAction, () => HideModalAction] => {

  const [showFeedback, hideFeedback] = useModal(name);
  const showMainFeedback = useCallback(() => showFeedback(), [showFeedback]);
  const hideMainFeedback = useCallback(() => hideFeedback(), [hideFeedback]);

  return [showMainFeedback, hideMainFeedback];
};

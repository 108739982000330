import { useAnalyticsTrack } from '@chr/react-analytics';
import { ReloadEventComponent } from '@features/analytics/components/reload-events.component';
import { useEffect } from 'react';
import { Container } from 'typedi';
import LoginEventsComponent from './login-events.component';
import { RecommendedLoadsEventComponent } from './recommended-loads-events.component';
import { SearchEventsComponent } from './search-events.component';
import { SortActionEventComponent } from './sort-action-events.component';
import CashAdvanceEventsComponent from './cash-advance-events.component';

export const AnalyticsContainer = () => {
    const track = useAnalyticsTrack();
    useEffect(() => {
        Container.set('analytics-track', track);
    }, [track]);

    return (
      <>
          <LoginEventsComponent/>
          <SearchEventsComponent/>
          <RecommendedLoadsEventComponent/>
          <SortActionEventComponent/>
          <ReloadEventComponent/>
          <CashAdvanceEventsComponent/>
      </>);
};

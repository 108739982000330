import { Switch, Route } from "react-router";
import { AppRoute } from "@app/routesEnum";
import { SiteSection } from "@shared/components/analytics/site-section.component";
import { PrivateRoute } from "@shared/components/route/private-route.component";
import { LazyAccountSettingsPage } from './index';

export default function AccountSettingRoutes() {
  return (
    <SiteSection name="Account settings">
      <PrivateRoute path={AppRoute.ACCOUNT_SETTINGS}>
        <Switch>
          <Route path={AppRoute.ACCOUNT_SETTINGS} exact component={LazyAccountSettingsPage} />
        </Switch>
      </PrivateRoute>
    </SiteSection>
  )
}
import { Container } from 'typedi';
import { Subject } from 'rxjs/Subject';

import { Store } from 'store/index';
import { fetchOffers } from 'shared/offers/redux/offers.actions';
import { hideModal } from 'shared/components/modal/modal.actions';
import { OFFER_MODAL } from 'pages/offers/modals/offer-modal.context';
import { refreshAvailableLoads } from 'store/epics/find-loads/find-loads-base.actions';

export interface InternalAPI {
  fetchOffers: () => void;
  refreshLoads: () => void;
  refreshBINLoadsSubject: Subject<any>;
  sessionStorage: {[key: string]: string};
}

const API: InternalAPI = {
  fetchOffers: () => Container.get(Store).dispatch(fetchOffers()) && Container.get(Store).dispatch(hideModal(OFFER_MODAL)),
  refreshLoads: () => Container.get(Store).dispatch(refreshAvailableLoads()),
  refreshBINLoadsSubject: new Subject(),
  sessionStorage: null,
};

const INTERNAL_API_KEY = Container.get<string>('appConstants.internalAPIKey');

window[INTERNAL_API_KEY] = API; // public API entry point
Container.set('internalAPI', API);

import React, { useEffect, useMemo, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useBrazeContext } from './initialization';
import { CustomContentCard } from './custom-content-card';
import { useWindowLocation } from '@features/analytics/hooks/use-window-location.hook';
import { CustomContentCardData } from './types';

export const PageContentCardFeed = () => {
    const [open, setOpen] = useState(false);
    const { pathname } = useWindowLocation();
    const { contentCards, triggerLogCustomEvent, handleCardDismissal } = useBrazeContext();
    const [currentCardIndex, setCurrentCardIndex] = useState(0);

    const pageContentCards = useMemo(() => (contentCards ?? []).reduce((cards: CustomContentCardData[], each: CustomContentCardData) => {
            const isDuplicate = cards.some(({ extras }) => !!extras?.card_id && extras?.card_id === each.extras?.card_id);
            const isValidCard = !isDuplicate && pathname.includes(each.extras?.page_name);
            if (isValidCard) {
                return [...cards, each];
            }
            return cards;
        }, [])
        .sort((a, b) => Number(a.extras?.card_index) - Number(b.extras?.card_index)), [contentCards, pathname]);

    const currentCard = pageContentCards[currentCardIndex];

    const triggerCardClickEvent = () => {
        const { card_click_event, card_id } = currentCard.extras;
        triggerLogCustomEvent(card_click_event, { card_id });
        handleCardDismissal(currentCard);

        if (currentCardIndex < pageContentCards.length - 1) {
            setCurrentCardIndex(currentCardIndex + 1);
        } else {
            setOpen(false);
        }
    };

    const triggerCardDismissEvent = () => {
        pageContentCards.slice(currentCardIndex).forEach(c => {
            const { card_dismiss_event, card_id } = c.extras;
            triggerLogCustomEvent(card_dismiss_event, { card_id });
            handleCardDismissal(c);
        });
        setOpen(false);
    };

    useEffect(() => {
        if (pageContentCards.length) {
            setOpen(true);
        }
    }, [pageContentCards.length]);

    return !!currentCard && (
        <Snackbar
            data-testid="page-onboarding-feed"
            key="page-onboarding-feed"
            sx={{ '& .MuiSnackbarContent-root': { padding: 0, marginTop: currentCard?.extras?.verticalAnchor === 'top' ? 10 : 0 }, '& .MuiSnackbarContent-message': { padding: 0 } }}
            open={open}
            anchorOrigin={{ vertical: currentCard.extras?.verticalAnchor ?? 'bottom', horizontal: currentCard.extras?.horizontalAnchor ?? 'left' }}
            message={<CustomContentCard card={currentCard} handleCardClick={triggerCardClickEvent} handleCardDismissal={triggerCardDismissEvent} />}
        />
    );
}
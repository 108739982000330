import { useStoredUser, useStoredCarrierDetail } from '@app/hooks/store/use-stored-user.hook';
import { DataDogProvider } from '@features/datadog';
import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';
import { connectSignalR } from 'store/epics/app.actions';
import { FeatureFlagProvider } from '@containers/FeatureFlagProvider';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { updateCarrierForUser } from 'store/epics/carrier/carrier.actions';
import FullStory from 'react-fullstory';
import { BranchProvider } from '@features/branch-io';

interface Props extends JSX.IntrinsicAttributes {
 children: ReactNode | ReactNode[] | any;
}

const Consumer = ({ children }: Props) => {
 return <>{children}</>;
};

const WrapperConsumer = withLDConsumer()(Consumer);

const FSProps = {
 debug: false,
 org: '16VT8W',
 namespace: 'FS',
};

export const BootStrap = ({ children }: Props) => {
 const [wsConnected, setWsConnected] = useState(false);
 const updateCarrierInterval = 600000; //10 minutes

 const user = useStoredUser();
 const carrier = useStoredCarrierDetail();

 const { isAuthenticated } = useSelector<NavCarrierState>(s => s.auth) as AuthState;
 const connectWebSockets = useDispatcher(connectSignalR);
 const updateCarrier = useDispatcher(updateCarrierForUser);

 useEffect(() => {
  if (isAuthenticated && !wsConnected) {
   connectWebSockets();
   setWsConnected(true);
  }
 }, [isAuthenticated]);

 useEffect(() => {
  // do not update carrier details for vendors
  if (user?.carrierCode && !user?.carrierCode.startsWith('V')) {
   const interval = setInterval(async () => {
    updateCarrier();
   }, updateCarrierInterval);
   return () => clearInterval(interval);
  }
 }, [user]);

 return (
  <DataDogProvider>
   <BranchProvider />
   <FullStory {...FSProps} />
   <FeatureFlagProvider {...{ user, carrier }}>
    <WrapperConsumer>{children}</WrapperConsumer>
   </FeatureFlagProvider>
  </DataDogProvider>
 );
};

import React from 'react';
import { useEffect, useMemo } from 'react';
import { useAnalyticsTrack, userDetectedEvent } from '@chr/react-analytics';

import { useStoredUser } from 'app/hooks/store/use-stored-user.hook';
import { useCarrierDetails } from 'app/hooks/store/use-carrier-details.hook';
import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';
import { User } from 'shared/models/user.model';

export const useUserAnalytics = (user: User, carrier: CarrierDetail) => {
  const track = useAnalyticsTrack();
  const userEvent = useMemo(
    () => user && userDetectedEvent({
      userId: user.userId?.toString(),
      username: user.username?.toString(),
      carrierCode: carrier ? carrier.carrierCode : user.carrierCode,
      carrierName: carrier?.name,
      carrierProgram: carrier?.capCode,
      carrierDivision: user.carrierDivision?.division,
    }),
    [user, carrier]
  );

  useEffect(() => {
      if (userEvent) {
        track(userEvent);
      } else if (window['appEventData']?._reset) {
        window['appEventData']._reset();
      }
    }, [userEvent]
  );
};

export const UserTracking: React.FC = () => {
  const user = useStoredUser();
  return <UserTrackingContainer user={user}/>;
};

interface UserTrackingContainerProps {
  user: User;
}

export const UserTrackingContainer: React.FC<UserTrackingContainerProps> = ({user}) => {
  const carrier = useCarrierDetails();

  useUserAnalytics(user, carrier);

  useEffect(() => {
    return () => window['appEventData']?._reset && window['appEventData']._reset();
  }, []);

  return null;
};

import { Switch, Route } from "react-router";
import { AppRoute } from "@app/routesEnum";
import { SiteSection } from "@shared/components/analytics/site-section.component";
import { UnauthenticatedRoute } from "@shared/components/route/unauthenticated-route.component";
import { LazyUnableToSigninOnWebPage } from 'features/okta/index';

export default function OktaRoutes() {

  return (
    <>
      <SiteSection name="Okta">
        <UnauthenticatedRoute path={AppRoute.UNABLE_TO_SIGNIN_ON_WEB}>
          <Switch>
            <Route path={AppRoute.UNABLE_TO_SIGNIN_ON_WEB} exact component={LazyUnableToSigninOnWebPage} />
          </Switch>
        </UnauthenticatedRoute>
      </SiteSection>
    </>
  )
}
import { useAnalyticsTrack } from '@chr/react-analytics';
import { CashAdvanceSourceType } from 'shared/models/cash-advances/cash-advance-options-source.model';
import { useMemo, useEffect } from 'react';
import { EVENT_TYPES } from '../constants';
import { useAnalyticsEventMetaData } from '../hooks/use-analytics-event-metadata.hook';
import { useCashAdvanceEvents } from '../redux/analytics-v2.selectors';
import { getWarehouseCommaSeparatedValues } from '@features/analytics/utils/warehouse-address-flattener';

const CashAdvanceEventsComponent = () => {
  const track = useAnalyticsTrack();
  const eventMetaData = useAnalyticsEventMetaData();
  const {
    load: loadDetail,
    cashAdvanceOptions,
    cashAdvanceContinued,
    cashAdvanceReviewContinued,
    cashAdvanceSubmitted,
    requestedAmount,
    type,
    transactionFee,
    cashAdvanceRequested,
    cashAdvanceError,
    cashAdvanceSuccess,
    responseCode,
    cashAdvance,
    passcodeOutcome,
    cashAdvanceViewSuccess,
    cashAdvanceViewError,
  } = useCashAdvanceEvents();

  const load = useMemo(() => {
    if (!loadDetail) {
      return null;
    }

    return {
      number: loadDetail.number,
      loadDistance: loadDetail.distance,
      stopCount: loadDetail.stops?.length ?? 0,
      origin: getWarehouseCommaSeparatedValues(loadDetail.getFirstPick()?.warehouse),
      finalDestination: getWarehouseCommaSeparatedValues(loadDetail.getLastDrop()?.warehouse),
    };
  }, [loadDetail]);

  const advance = useMemo(() => {
    if (!cashAdvanceOptions) {
      return null;
    }

    const fuelCardAvailable = cashAdvanceOptions.cashAdvanceSources.find(opt => opt.cashAdvanceSourceType === CashAdvanceSourceType.EFSFuelCard).isEnabled ? '1' : '0';

    return {
      amount: cashAdvanceOptions.availableBalance,
      fuelCardAvailable,
      requestedAmount,
      type: type === 1 ? 'EFSFuelCard' : 'TChek',
      transactionFee,
    };
  }, [cashAdvanceOptions, requestedAmount, type, transactionFee]);

  const advanceRequest = useMemo(() => {
    if (!cashAdvanceOptions) {
      return null;
    }
    return { amount: cashAdvanceOptions.availableBalance };
  }, [cashAdvanceOptions]);

  const advanceFail = useMemo(() => {
    return { ...advance, responseCode, passcodeOutcome: passcodeOutcome ? '1' : '0' };
  }, [advance, responseCode, passcodeOutcome]);

  useEffect(() => {
    if (cashAdvanceSubmitted) {
      track({
        event: EVENT_TYPES.REVIEW_CASH_ADVANCE_SUBMIT,
        ...eventMetaData,
        advance,
        load,
      } as any);
    }
  }, [cashAdvanceSubmitted, track]);

  useEffect(() => {
    if (cashAdvanceRequested) {
      track({
        event: EVENT_TYPES.REQUEST_ADVANCE,
        ...eventMetaData,
        advance: advanceRequest,
        load,
      } as any);
    }
  }, [cashAdvanceRequested, track]);

  useEffect(() => {
    if (cashAdvanceContinued) {
      track({
        event: EVENT_TYPES.REQUEST_CASH_ADVANCE_CONTINUE,
        ...eventMetaData,
        advance,
        load,
      } as any);
    }
  }, [cashAdvanceContinued, track]);

  useEffect(() => {
    if (cashAdvanceReviewContinued) {
      track({
        event: EVENT_TYPES.REVIEW_CASH_ADVANCE_CONTINUE,
        ...eventMetaData,
        advance,
        load,
      } as any);
    }
  }, [cashAdvanceReviewContinued, track]);

  useEffect(() => {
    if (cashAdvanceError) {
      track({
        event: EVENT_TYPES.ADVANCE_FAIL,
        ...eventMetaData,
        advance: advanceFail,
        load,
      } as any);
    }
  }, [cashAdvanceError, track]);

  useEffect(() => {
    if (cashAdvanceSuccess) {
      track({
        event: EVENT_TYPES.ADVANCE_SUCCESS,
        ...eventMetaData,
        cashAdvance,
      } as any);
    }
  }, [cashAdvanceSuccess, track]);

  useEffect(() => {
    if (cashAdvanceViewError) {
      track({
        event: EVENT_TYPES.VIEW_CASH_ADVANCE_FAIL,
        ...eventMetaData,
        advance: advanceFail,
        load,
      } as any);
    }
  }, [cashAdvanceViewError, track]);

  useEffect(() => {
    if (cashAdvanceViewSuccess) {
      track({
        event: EVENT_TYPES.VIEW_CASH_ADVANCE_SUCCESS,
        ...eventMetaData,
        cashAdvance,
      } as any);
    }
  }, [cashAdvanceViewSuccess, track]);

  return null;
};

export default CashAdvanceEventsComponent;

import { AnyAction, combineReducers } from 'redux';

import { AvailableLoadSearchHistory } from 'shared/models/loads/available-load-search-history.model';
import { SearchHistoryLocations } from 'shared/models/recent-searches/search-history-locations.model';
import { SEARCH_MODIFIED } from 'shared/find-loads/redux/find-loads.actions';
import * as a from './search-history.actions';

export interface SearchHistoryState {
  open: boolean;
  history: AvailableLoadSearchHistory[] | SearchHistoryLocations; // V1 or V2
  preferredLanes: AvailableLoadSearchHistory[];
  activeEntry: AvailableLoadSearchHistory;
  filter: string;
}

export const open = (state = false, action: AnyAction) => {
  switch (action.type) {
    case a.HIDE_SEARCH_HISTORY:
    case a.SELECT_SEARCH_HISTORY:
      return false;
    case a.SHOW_SEARCH_HISTORY:
      return true;
    default:
      return state;
  }
};

export const history = (state = [], action: AnyAction) => {
  switch (action.type) {
    case a.FETCH_SEARCH_HISTORY_SUCCESS:
    case a.FETCH_SEARCH_HISTORY_LOCATIONS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export const preferredLanes = (state = [], action: AnyAction) => {
  switch (action.type) {
    case a.FETCH_SEARCH_HISTORY_SUCCESS:
      return action.payload.filter(model => model.isPreferredLane);
    default:
      return state;
  }
};

export const activeEntry = (state = null, action: AnyAction) => {
  switch (action.type) {
    case a.SELECT_SEARCH_HISTORY:
      return action.entry;
    case SEARCH_MODIFIED:
      return null;
    default:
      return state;
  }
};

export const filter = (state = '', action: AnyAction) => {
  switch (action.type) {
    case a.FILTER_SEARCH_HISTORY:
      return action.filter;
    default:
      return state;
  }
};

export const filterHistory = (entries: AvailableLoadSearchHistory[], value: string) => {
  const search = new RegExp(value, 'i');

  return entries.filter(entry => {
    return entry.originCity.match(search)
      || entry.originStateCode.match(search)
      || entry.originStateList.match(search)
      || entry.originCountryCode.match(search)
      || entry.destinationCity.match(search)
      || entry.destinationStateCode.match(search)
      || entry.destinationStateList.match(search)
      || entry.destinationCountryCode.match(search);
  });
};

export const searchHistory = combineReducers({
  open,
  history,
  preferredLanes,
  activeEntry,
  filter,
});

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from 'typedi';
import { UserRepository } from 'app/repositories/user.repository';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { setUserPreferredEmailInfo } from '@features/okta/redux/oktaSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const OktaPreferencesComponent = ({ children }: any) => {
  const { isOktaEnabled } = useFlags();
  const userRepo = Container.get(UserRepository);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    if (isOktaEnabled && user) {
      userRepo
        .getOktaPreferredEmail(user)
        .subscribe(res => dispatch(setUserPreferredEmailInfo(res)))
    }
  }, [user]);

  return (<>{children}</>);
}

import { AnyAction, combineReducers } from 'redux';

import * as a from './my-shipments-search.actions';
import { FormGroupStructure } from 'app/forms/structure/form-group.structure';
import { createStructureReducer } from 'app/forms/structure/form-structure.reducers';
import { MY_SHIPMENTS_SEARCH_FORM_KEY, createMyShipmentsSearchFormStructure } from './form/my-shipments-search.form';
import { SortOrder } from 'shared/enums/sort-order.enum';

export const currentSearchValues = (state = null, action: AnyAction) => {
  switch (action.type) {
    case a.SET_CURRENT_SHIPMENT_SEARCH_VALUES:
      return action.values;
    case a.RESET_CURRENT_SHIPMENT_SEARCH_VALUES:
      return null;
    default:
      return state;
  }
};

export const pendingSearchValues = (state = null, action: AnyAction) => {
  switch (action.type) {
    case a.SET_PENDING_SHIPMENT_SEARCH_VALUES:
      return action.values;
    case a.RESET_PENDING_SHIPMENT_SEARCH_VALUES:
      return null;
    default:
      return state;
  }
};

export const errorMessage = (state = null, action: AnyAction) => {
  switch (action.type) {
    case a.SET_SHIPMENT_SEARCH_ERROR:
      return action.error;
    case a.RESET_SHIPMENT_SEARCH_ERROR:
      return null;
    default:
      return state;
  }
};

export const pagination = (state: PaginationParams = {PageNumber: 0, PageSize: 25}, action: AnyAction) => {
  switch (action.type) {
    case a.SET_SHIPMENT_PAGINATION_PARAMS:
      return action.params;
    case a.MY_SHIPMENTS_SEARCH_SUCCESS:
    case a.SET_SHIPMENT_SORT_PARAMS:
    case a.SET_SHIPMENT_SEARCH_PARAMS:
      // page number tends to reset to page 0 when other params are updated.
      return {
        ...state,
        PageNumber: 0
      };
    default:
      return state;
  }
};

export const sort = (state: MyShipmentSortParams = {Field: 'LoadNumber', Direction: SortOrder.ASC}, action: AnyAction) => {
  switch (action.type) {
    case a.SET_SHIPMENT_SORT_PARAMS:
      return action.params;
    default:
      return state;
  }
};

export const search = (state: MyShipmentsSearchRequest = {}, action: AnyAction) => {
  switch (action.type) {
    case a.SET_SHIPMENT_SEARCH_PARAMS:
      return action.params;
    default:
      return state;
  }
};

export const myShipmentsSearchReducer = combineReducers({
  structure: createStructureReducer(createMyShipmentsSearchFormStructure(), MY_SHIPMENTS_SEARCH_FORM_KEY),
  params: combineReducers({
    pagination, sort, search
  }),
  currentSearchValues,
  pendingSearchValues,
  errorMessage,
});

export interface MyShipmentsSearchState {
  structure: FormGroupStructure;
  currentSearchValues: MyShipmentsSearchFormValues;
  pendingSearchValues: MyShipmentsSearchFormValues;
  errorMessage: string | JSX.Element;
  params: {
    sort: MyShipmentSortParams,
    pagination: PaginationParams,
    search: MyShipmentsSearchRequest,
  };
}

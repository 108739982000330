import { SSOLoginWrapper, WrapperStyles, TextLinkStyles } from './styles';
import ForceAuthDialog from './components/ForceAuthDialog';
import LoginWithSSOButton from './components/LoginWithSSOButton';
import LoginWithOktaForm from './components/LoginWithOktaForm';
import { lazy } from 'react';
export * from './config';

const LazyUnableToSigninOnWebPage = lazy(() =>
  import(/* webpackChunkName: "unableToSinginOnWeb", webpackPrefetch: true */ '@features/okta/components/UnableToSigninOnWebsite').then(({ UnableToSigninOnWebsite }) => ({
	default: UnableToSigninOnWebsite,
  }))
);

export {
  SSOLoginWrapper,
  WrapperStyles,
  TextLinkStyles,
  ForceAuthDialog,
  LoginWithSSOButton,
  LoginWithOktaForm,
  LazyUnableToSigninOnWebPage
};

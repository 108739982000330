import { Warehouse } from 'shared/models/warehouse.model';

export function getWarehouseCommaSeparatedValues(warehouse: Warehouse) {
  const address = warehouse?.address;
  if (!address) {
    return null;
  }

  const { city = '', stateCode = '', countryCode = '', geolocation } = address;
  return `${city}, ${stateCode}, ${countryCode}, ${geolocation?.latitude}, ${geolocation?.longitude}`;
}

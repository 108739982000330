import { Container } from 'typedi';

import { Store } from 'store/index';
import { epic$ } from 'store/epics/root.epics';
import { myLoadsReducers } from './my-loads.reducers';
import { myLoadsEpic } from './my-loads.epics';

const store = Container.get(Store);

store.reducerManager.add('myLoads', myLoadsReducers);
store.dispatch({type: 'init-myLoads'});

epic$.next(myLoadsEpic);

import { AnyAction, combineReducers } from 'redux';

import { MyShipmentsSearchState, myShipmentsSearchReducer } from '../search/my-shipments-search.reducers';
import { MY_SHIPMENTS_SEARCH_SUCCESS, MY_SHIPMENTS_SEARCH_FAILURE } from '../search/my-shipments-search.actions';
import { ShipmentSummary } from 'shared/models/shipments/shipment-summary.model';

export const shipments = (state = [], action: AnyAction) => {
  switch (action.type) {
    case MY_SHIPMENTS_SEARCH_SUCCESS:
      return action.payload.results;
    case MY_SHIPMENTS_SEARCH_FAILURE:
      return [];
    default:
      return state;
  }
};

export const totalRecords = (state = 0, action: AnyAction) => {
  switch (action.type) {
    case MY_SHIPMENTS_SEARCH_SUCCESS:
      return action.payload.totalCount;
    default:
      return state;
  }
};

export const myShipmentsReducers = combineReducers({
  search: myShipmentsSearchReducer,
  results: combineReducers({shipments, totalRecords}),
});

export interface MyShipmentsState {
  search: MyShipmentsSearchState;
  results: {
    shipments: ShipmentSummary[],
    totalRecords: number;
  };
}

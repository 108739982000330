import { AnyAction, combineReducers } from 'redux';

import * as a from './my-loads-search.actions';
import { FormGroupStructure } from 'app/forms/structure/form-group.structure';
import { createStructureReducer } from 'app/forms/structure/form-structure.reducers';
import { MY_LOADS_SEARCH_FORM_KEY, createMyLoadsSearchFormStructure } from './form/my-loads-search.form';
import { SortOrder } from 'shared/enums/sort-order.enum';

export const currentSearchValues = (state = null, action: AnyAction) => {
  switch (action.type) {
    case a.SET_CURRENT_SEARCH_VALUES:
      return action.values;
    case a.RESET_CURRENT_SEARCH_VALUES:
      return null;
    default:
      return state;
  }
};

export const pendingSearchValues = (state = null, action: AnyAction) => {
  switch (action.type) {
    case a.SET_PENDING_SEARCH_VALUES:
      return action.values;
    case a.RESET_PENDING_SEARCH_VALUES:
      return null;
    default:
      return state;
  }
};

export const errorMessage = (state = null, action: AnyAction) => {
  switch (action.type) {
    case a.SET_SEARCH_ERROR:
      return action.error;
    case a.RESET_SEARCH_ERROR:
      return null;
    default:
      return state;
  }
};

export const pagination = (state: PaginationParams = {PageNumber: 1, PageSize: 25}, action: AnyAction) => {
  switch (action.type) {
    case a.SET_PAGINATION_PARAMS:
      return action.params;
    case a.MY_LOADS_SEARCH_SUCCESS:
    case a.SET_SORT_PARAMS:
    case a.SET_SEARCH_PARAMS:
      // page number tends to reset to page 1 when other params are updated.
      return {
        ...state,
        PageNumber: 1
      };
    default:
      return state;
  }
};

export const sort = (state: SortParams = {SortBy: 2, SortOrder: SortOrder.ASC}, action: AnyAction) => {
  switch (action.type) {
    case a.SET_SORT_PARAMS:
      return action.params;
    default:
      return state;
  }
};

export const search = (state: MyLoadsSearchRequest = {}, action: AnyAction) => {
  switch (action.type) {
    case a.SET_SEARCH_PARAMS:
      return action.params;
    default:
      return state;
  }
};


export const defaultSorting = (state: boolean = true, action: AnyAction) => {
  switch (action.type) {
    case a.SET_SEARCH_PARAMS:
      return true;
    case a.SET_SORT_PARAMS:
      return false;
    default:
      return state;
  }
};

export const myLoadsSearchReducer = combineReducers({
  structure: createStructureReducer(createMyLoadsSearchFormStructure(), MY_LOADS_SEARCH_FORM_KEY),
  params: combineReducers({
    pagination, sort, search, defaultSorting
  }),
  currentSearchValues,
  pendingSearchValues,
  errorMessage,
});

interface MyLoadsSearchParams {
  sort: SortParams,
  pagination: PaginationParams,
  search: MyLoadsSearchRequest,
  defaultSorting: boolean,

}

export interface MyLoadsSearchState {
  structure: FormGroupStructure;
  currentSearchValues: MyLoadsSearchFormValues;
  pendingSearchValues: MyLoadsSearchFormValues;
  errorMessage: string | JSX.Element;
  params: MyLoadsSearchParams;
}

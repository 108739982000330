import React from 'react';
import { Action, AnyAction } from 'redux';
import { Container } from 'typedi';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/concatMap';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/mergeMap';

import { AvailableLoadsRepository } from 'app/repositories/available-loads.repository';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';

import * as a from './search-history.actions';
import { SEARCH_AVAILABLE_LOADS_SUCCESS } from 'shared/find-loads/redux/find-loads.actions';

interface EpicDependencies {
  searchHistoryRepo: AvailableLoadsRepository;
}

type SearchHistoryEpic<OutputAction extends Action = AnyAction> = NavCarrierEpic<OutputAction, EpicDependencies>;

// V2 - using redis
export const fetchSearchHistoryLocationsEpic: SearchHistoryEpic = (action$, state$, { searchHistoryRepo }) =>
  action$.ofType(a.FETCH_SEARCH_HISTORY_LOCATIONS, SEARCH_AVAILABLE_LOADS_SUCCESS)
    .mergeMap(() =>
      searchHistoryRepo.getSearchHistoryLocations(state$.value?.auth?.user?.userId)
        .map((searchHistory) => a.fetchSearchHistoryLocationsSucceeded(searchHistory))
        .catch((res: AjaxError) => ActionsObservable.of(a.fetchSearchHistoryLocationsFailed(res)))
    );

// V1 - cleanup coming in future story
export const triggerFetchSearchHistoryEpic: SearchHistoryEpic = action$ =>
  action$.ofType(a.SAVE_SEARCH_HISTORY_SUCCESS, a.DELETE_SEARCH_HISTORY_SUCCESS, a.CLEAR_SEARCH_HISTORY_SUCCESS)
    .mapTo(a.fetchSearchHistory());

export const fetchSearchHistoryEpic: SearchHistoryEpic = (action$, state$, { searchHistoryRepo }) =>
  action$.ofType(a.FETCH_SEARCH_HISTORY)
    .mergeMap(() =>
      searchHistoryRepo.getSearchHistory()
        .map((searchHistory) => a.fetchSearchHistorySucceeded(searchHistory))
        .catch((res: AjaxError) => ActionsObservable.of(a.fetchSearchHistoryFailed(res)))
    );

export const saveSearchHistoryEpic: SearchHistoryEpic = (action$, state$, { searchHistoryRepo }) =>
  action$.ofType(a.SAVE_SEARCH_HISTORY)
    .mergeMap(({ entry }) =>
      searchHistoryRepo.saveSearchHistory(entry)
        .map((result) => a.saveSearchHistorySucceeded(result))
        .catch((error: AjaxError) => ActionsObservable.of(a.saveSearchHistoryFailed(error)))
    );

export const deleteSearchHistoryEpic: SearchHistoryEpic = (action$, state$, { searchHistoryRepo }) =>
  action$.ofType(a.DELETE_SEARCH_HISTORY)
    .mergeMap(({ entry }) =>
      searchHistoryRepo.deleteSearchHistory(entry)
        .map(() => a.deleteSearchHistorySucceeded())
        .catch((error: AjaxError) => ActionsObservable.of(a.deleteSearchHistoryFailed(error)))
    );

export const clearSearchHistoryEpic: SearchHistoryEpic = (action$, state$, { searchHistoryRepo }) =>
  action$.ofType(a.CLEAR_SEARCH_HISTORY)
    .mergeMap(() =>
      searchHistoryRepo.clearSearchHistory()
        .map(() => a.clearSearchHistorySucceeded())
        .catch((error: AjaxError) => ActionsObservable.of(a.clearSearchHistoryFailed(error)))
    );

export const markSearchHistoryPreferredEpic: SearchHistoryEpic = action$ =>
  action$.ofType(a.MARK_SEARCH_HISTORY_PREFERRED)
    .concatMap(({ entry }) => {
      entry.isPreferredLane = !entry.isPreferredLane;
      return ActionsObservable.of(a.saveSearchHistory(entry));
    });

export const searchHistoryEpic = (action$, state$) =>
  combineEpics(
    triggerFetchSearchHistoryEpic,
    fetchSearchHistoryEpic,
    fetchSearchHistoryLocationsEpic,
    saveSearchHistoryEpic,
    deleteSearchHistoryEpic,
    clearSearchHistoryEpic,
    markSearchHistoryPreferredEpic
  )(action$, state$, {
    searchHistoryRepo: Container.get(AvailableLoadsRepository)
  });

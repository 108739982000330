import { useMemo } from 'react';

import { useURLMapper } from 'app/legacy/hooks/use-url-mapper.hook';
import { useRouterLocation } from 'app/hooks/store/use-router-location.hook';

export const useBetaUrl = (): string => {
  const {pathname, search} = useRouterLocation();
  const mapper = useURLMapper();

  return useMemo(() => pathname && mapper?.toBeta(pathname, search), [mapper, pathname]);
};

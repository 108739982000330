import { Container } from 'typedi';

import { Store } from 'store/index';
import { epic$ } from 'store/epics/root.epics';
import { invoiceReducers } from './manage-invoices.reducers';
import { manageInvoicesEpics } from './manage-invoices.epics';

const store = Container.get(Store);

store.reducerManager.add('invoices', invoiceReducers);
store.dispatch({type: 'init-invoices'});

epic$.next(manageInvoicesEpics);

import * as React from 'react';
import { useAnalyticsTrack } from '@chr/react-analytics';
import { EVENT_TYPES } from '../constants';
import { useSearchEvents, useSortActionEvents } from '../redux/analytics-v2.selectors';
import { generateEventSequence } from '../utils/event-sequence-generator';
import { useAnalyticsEventMetaData } from '../hooks/use-analytics-event-metadata.hook';
import { useAdobeSearchEventsDataMapper } from '../hooks/use-adobe-search-events-data-mapper.hook';

export const SearchEventsComponent = () => {
  const track = useAnalyticsTrack();
  const sortEvent = useSortActionEvents();
  const eventMetaData = useAnalyticsEventMetaData();
  const { isButtonClick, searchCriteria, searchResults, source, searchCorrelationId, pageSize, pageNumber, totalVisibleResults, subsetOfResultsCount, isOriginRecentSearch, isDestinationRecentSearch } = useSearchEvents();
  const { adobeSearchCriteria: search, adobeSearchResults: loadList } = useAdobeSearchEventsDataMapper({ searchCriteria, searchResults });

  const eventData = React.useMemo(() => {
    const { timeGMT, sequenceNumber: sessionEventSequenceNumber } = generateEventSequence();
    const { sortBy, sortOrder: sortType } = sortEvent;

    const data: KeyValuePair = {
      ...eventMetaData,
      sortBy,
      sortType,
      searchCorrelationId,
      sequence: {
        isInfinityWorkflow: true,
        timeGMT,
        sessionEventSequenceNumber,
      },
    };

    return data;
  }, [
    sortEvent,
    searchCorrelationId,
    searchCriteria,
    searchResults,
    eventMetaData,
  ]);

  React.useEffect(() => {
    if (search) {
      const data = {
        ...eventData,
        search: {
          ...search,
          source,
          usedOriginRecentSearch: isOriginRecentSearch,
          usedDestinationRecentSearch: isDestinationRecentSearch,
        }
      } as KeyValuePair;

      track({ event: EVENT_TYPES.SUBMIT_SEARCH, ...data });
    }
  }, [search, track, searchCorrelationId]);

  React.useEffect(() => {
    if (searchResults?.length) {
      // Because Adobe is not displaying zero number value, we will use the below format to pass zero.
      const loadsWithBinCost = subsetOfResultsCount ? subsetOfResultsCount : 0;
      const data = {
        ...eventData,
        experiments: { findLoadsExperiment: Number(eventData.features?.bounceWarningExperiment?.value) },
        search: { ...search, source },
        resultSet: { loadList, pageSize, pageNumber, totalVisibleResults, subsetOfResultsCount: loadsWithBinCost },
      } as KeyValuePair;

      track({ event: EVENT_TYPES.SEARCH_RESULTS, ...data });

    }

    if (searchResults?.length === 0) {
      const data = { ...eventData, search: { ...search, source } } as KeyValuePair;
      track({ event: EVENT_TYPES.LOAD_UNAVAILABLE_404, ...data });
    }
  }, [searchResults, track]);

  return null;
};

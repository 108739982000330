import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { CardHeader, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { CustomContentCardData } from './types';
import './custom-content-card.scss';

export interface CustomContentCardProps {
    card: CustomContentCardData;
    handleCardDismissal?: () => void;
    handleCardClick?: () => void;
}

export const CustomContentCard = ({ card, handleCardDismissal, handleCardClick }: CustomContentCardProps) => {
    const cardStyles = {
        backgroundColor: '#0071C7',
        color: '#FFFF',
    };

    const renderDismissButton = () => (
        <IconButton
            data-testid="dismissal-button"
            onClick={handleCardDismissal}
            sx={{ color: '#36383A' }}
            aria-label="dismiss button">
            <CloseIcon />
        </IconButton>
    );

    const renderCardImage = () => !!card?.imageUrl && (
        <CardMedia
            sx={{ height: 140 }}
            image={card?.imageUrl}
            title="Card Image Url"
        />
    );

    return !!card && (
        <Card className="custom-content-card" sx={cardStyles}>
            <CardHeader title={card.title} action={renderDismissButton()} />
            {renderCardImage()}
            <CardContent sx={{ paddingTop: card?.imageUrl ? 2 : 0 }}>
                <Typography variant="body2">
                    {card.description}
                </Typography>
            </CardContent>
            {!!card.extras?.card_cta &&
                (<CardActions sx={{ justifyContent: 'flex-end' }}
                    disableSpacing>
                    <Button
                        sx={{ color: '#FFFF' }}
                        size="medium"
                        onClick={handleCardClick}
                        data-testid="call-to-action-button">
                        {card.extras.card_cta}
                    </Button>
                </CardActions>)}
        </Card>
    );
}

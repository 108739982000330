import { Container } from 'typedi';

import { Store } from 'store';
import { epic$ } from 'store/epics/root.epics';
import { findLoadsReducer } from 'shared/find-loads/redux/find-loads.reducers';
import { findLoadsEpic } from 'shared/find-loads/redux/find-loads.epics';
import { processAvailableLoadsSearchParams } from 'shared/find-loads/redux/find-loads.actions';
import { setInitialSearchCorrelationId } from 'features/analytics/redux/analytics-v2.actions';

const store = Container.get(Store);

store.reducerManager.add('findLoads', findLoadsReducer);
store.dispatch({type: 'init-findLoads'});

epic$.next(findLoadsEpic);
store.dispatch(setInitialSearchCorrelationId());

import { combineReducers } from 'redux';

import * as a from './manage-invoices.actions';
import { SortOrder } from 'shared/enums/sort-order.enum';

export const search = (state = {}, action) => {
  switch (action.type) {
    case a.SET_INVOICE_SEARCH_CRITERIA:
      return action.criteria;
    case a.RESET_INVOICE_SEARCH_CRITERIA:
      return {};
    default:
      return state;
  }
};
const defaultPagination = {pageNumber: 1, pageSize: 25};
export const pagination = (state = defaultPagination, action) => {
  switch (action.type) {
    case a.SET_INVOICE_SEARCH_PAGINATION:
      return action.criteria;
    case a.SET_INVOICE_SEARCH_CRITERIA: // reset pagination when changing search criteria
      return {...state, pageNumber: 1};
    default:
      return state;
  }
};

const defaultSort = {
  sortByColumnName: 'ID',
  sortOrder: SortOrder.ASC
};
export const sorting = (state = defaultSort, action) => {
  switch (action.type) {
    case a.SET_INVOICE_SEARCH_SORTING:
      return action.criteria;
    default:
      return state;
  }
};
export const results = (state = null, action) => {
  switch (action.type) {
    case a.SET_INVOICE_SEARCH_RESULTS:
      return action.results;
    case a.RESET_INVOICE_SEARCH_RESULTS:
      return null;
    default:
      return state;
  }
};
export const error = (state = '', action) => {
  switch (action.type) {
    case a.SET_INVOICE_SEARCH_ERROR:
      return action.error;
    case a.SET_INVOICE_SEARCH_CRITERIA:
    case a.SET_INVOICE_SEARCH_SORTING:
    case a.SET_INVOICE_SEARCH_PAGINATION:
      return '';
    default:
      return state;
  }
};

export const invoiceReducers = combineReducers({search, results, pagination, sorting, error});

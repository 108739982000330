import { Route } from 'react-router';
import { AppRoute } from '@app/routesEnum';
import { SiteSection } from '@shared/components/analytics/site-section.component';
import { LazySetUpUser } from './index';

export default function SetUpUserRoutes() {
 return (
  <SiteSection name='Set Up User'>
   <Route path={AppRoute.SET_UP_USER} exact component={LazySetUpUser} />
  </SiteSection>
 );
}

export interface ICarrierIcon {
    className?: string;
    alt?: string;
    width?: string;
}

type ICarrierSvg = Partial<ICarrierIcon>;

export const ChrBrandLogo = ({ className, alt, ...rest }: ICarrierSvg) => {
    const style = "#00a3e0";
    const style2 = "#fff";

    return (
        <svg {...{ className, alt }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1852.49 230.14" {...rest} >
            <g id="Layer_2" data-name="Layer 2">
                <g id="chr-navispherecarrier-logo">
                    <g id="chr">
                        <polygon points="73.32 54.77 94.76 42.4 94.76 93.95 99.65 96.78 104.55 93.95 104.55 42.4 125.98 54.77 130.88 46.29 99.65 28.27 68.43 46.29 73.32 54.77" fill={style} />
                        <polygon points="60.6 168.02 39.17 155.64 83.81 129.87 83.81 124.21 78.92 121.39 34.27 147.16 34.27 122.41 24.48 122.41 24.48 158.47 55.71 176.5 60.6 168.02" fill={style} />
                        <path d="M99.65,0,0,57.53V172.6l99.65,57.54L199.3,172.6V57.53Zm89.86,167L99.65,218.83,9.79,167V63.19L99.65,11.31l89.86,51.88Z" fill={style} />
                        <polygon points="138.7 62.12 160.14 74.49 99.65 109.42 39.17 74.49 60.6 62.12 55.7 53.64 24.48 71.67 24.48 107.72 34.27 107.72 34.27 82.97 94.76 117.9 94.76 187.74 73.32 175.36 68.42 183.84 99.65 201.87 130.88 183.84 125.98 175.36 104.55 187.74 104.55 117.89 165.03 82.97 165.03 107.72 174.82 107.72 174.82 71.67 143.6 53.64 138.7 62.12" fill={style} />
                        <polygon points="165.03 122.41 165.03 147.16 120.39 121.39 115.49 124.21 115.49 129.87 160.14 155.64 138.7 168.02 143.6 176.5 174.82 158.47 174.82 122.41 165.03 122.41" fill={style} />
                        <path d="M190.8,186.34h2.73c1.68,0,2.52.68,2.52,2a1.74,1.74,0,0,1-.55,1.44,2.52,2.52,0,0,1-1.36.54l2.07,3.19H195l-2-3.11H191.9v3.11h-1.1Zm1.1,3.21H193c.24,0,.48,0,.71,0a2,2,0,0,0,.62-.14,1,1,0,0,0,.43-.37,1.2,1.2,0,0,0,.16-.68,1,1,0,0,0-.15-.58.92.92,0,0,0-.38-.35,1.9,1.9,0,0,0-.51-.15l-.57,0H191.9Z" fill={style} />
                        <path d="M193.16,197.28a7.33,7.33,0,1,1,7.33-7.33,7.33,7.33,0,0,1-7.33,7.33m0-13.42a6.09,6.09,0,1,0,6.09,6.09,6.09,6.09,0,0,0-6.09-6.09" fill={style} />
                        <path d="M309.14,144.36c-3.45,4.64-9.43,11-16.87,11H275.68a16.62,16.62,0,0,1-8.24-2.39c-2.79-1.6-8.1-7-9.69-9.43a13.37,13.37,0,0,1-2.13-7.57V94.16a13,13,0,0,1,2.13-7.44c1.59-2.26,6.9-8,9.69-9.56a18.2,18.2,0,0,1,8.24-2.39h16.59c7.44,0,13.42,6.51,16.87,11a43.32,43.32,0,0,1,2.79,4.65l-13.55,3.85s-4-4.91-6-6.11a.82.82,0,0,0-.53-.13H276.21a1.36,1.36,0,0,0-.53.13,20.67,20.67,0,0,0-6,5.85,5.52,5.52,0,0,0-.13,1.46v40.37c0,1.59,4.11,5,6.11,6.11.13.13.79.13.92.13h15.14a1.49,1.49,0,0,0,.67-.13c2.79-1.33,6-6.24,6-6.24l13.55,3.58a24.48,24.48,0,0,1-2.79,5.05" fill={style2} />
                        <rect x="324.15" y="137.98" width="16.33" height="16.73" fill={style2} />
                        <polygon points="397.32 154.72 397.32 121.91 368.37 121.91 368.37 154.72 354.43 154.72 354.43 75.3 368.37 75.3 368.37 108.63 397.32 108.63 397.32 75.3 411.27 75.3 411.27 154.72 397.32 154.72" fill={style2} />
                        <rect x="425.21" y="137.98" width="16.33" height="16.73" fill={style2} />
                        <path d="M539.69,113.41a44.71,44.71,0,0,1-7.17,7,12.45,12.45,0,0,1-3.45,1.86l15.8,32.41H529.73L515,123.37H499.72v31.35h-14V75.3h37.32a17.16,17.16,0,0,1,9.43,2.92,46.65,46.65,0,0,1,7.17,7.31,14.82,14.82,0,0,1,2.39,8.1v11.68a14.48,14.48,0,0,1-2.39,8.1M528.14,93.76a2.86,2.86,0,0,0-.14-.93c-.79-1.46-2.92-4.12-4.25-4.25h-24v21.51h23.37c1.19,0,3.85-2.52,4.91-4.38a1.28,1.28,0,0,0,.14-.53Z" fill={style2} />
                        <path d="M615.92,143.43c-1.73,2.25-6.91,8-9.7,9.56a17,17,0,0,1-8.23,2.39H577.81a16.62,16.62,0,0,1-8.24-2.39c-2.79-1.6-8.1-7.31-9.69-9.56a13.37,13.37,0,0,1-2.13-7.57V94.29a13.37,13.37,0,0,1,2.13-7.57c1.59-2.26,6.9-8,9.69-9.56a16.62,16.62,0,0,1,8.24-2.39H598a17,17,0,0,1,8.23,2.39c2.79,1.59,8,7.3,9.7,9.56A13.44,13.44,0,0,1,618,94.29v41.57a13.44,13.44,0,0,1-2.12,7.57m-44.22-7.71a1.06,1.06,0,0,0,.13.54,21.2,21.2,0,0,0,5.84,5.71,1.49,1.49,0,0,0,.67.13h19.12a1.78,1.78,0,0,0,.66-.13,21.22,21.22,0,0,0,5.85-5.71,1.39,1.39,0,0,0,.13-.54v-41s0-.53-.13-.66a22,22,0,0,0-5.85-5.85,1.45,1.45,0,0,0-.66-.13H578.34s-.53,0-.67.13A22,22,0,0,0,571.83,94a1.78,1.78,0,0,0-.13.66Z" fill={style2} />
                        <path d="M689.09,144.62a44,44,0,0,1-7,7.17,17.63,17.63,0,0,1-9.57,2.93H635.18V75.3h37.31a17.07,17.07,0,0,1,9.57,3.06,44,44,0,0,1,7,7.17,14.55,14.55,0,0,1,2.26,8.23v9.83c0,5.58-2.12,8.5-5.18,11.55,3.06,3,5.18,5.84,5.18,11.42v10.09a14.19,14.19,0,0,1-2.26,8m-11.68-41.3V93.89a2.77,2.77,0,0,0-.13-.66c-1.2-2.13-3.72-4.65-4.92-4.65H649.12v20h23.37c1.2,0,3.86-2.39,4.79-4.38a2.31,2.31,0,0,0,.13-.93m0,32.8V126.7a1.68,1.68,0,0,0-.13-.67c-1.2-2.12-3.72-4.52-4.92-4.52H649.12v19.93h23.37c1.2,0,3.86-2.39,4.79-4.39a3.32,3.32,0,0,0,.13-.93" fill={style2} /><rect x="710.21" y="75.3" width="13.94" height="79.41" fill={style2} />
                        <polygon points="786.04 154.72 758.55 101.99 758.55 154.72 744.61 154.72 744.61 75.3 759.62 75.3 786.84 128.69 786.84 75.3 800.78 75.3 800.78 154.72 786.04 154.72" fill={style2} />
                        <path d="M875.82,143.43c-1.6,2.39-6.91,8-9.7,9.56a18.65,18.65,0,0,1-8.23,2.39H834.78c-7.83,0-13.41-6.38-16.86-11a27.45,27.45,0,0,1-2.39-4.12l13.14-4.38a32.65,32.65,0,0,0,5.85,6.11,2.31,2.31,0,0,0,.93.13h21.78A1.91,1.91,0,0,0,858,142c2-1.07,6-5.05,6-6.11V128a2.38,2.38,0,0,0-1.6-2.25l-34.52-9.83c-6.78-1.86-10.36-8.37-10.36-14.48V94.29a13.82,13.82,0,0,1,2-7.57c1.59-2.39,7-8,9.83-9.56a18.15,18.15,0,0,1,8.23-2.39h19.66c8,0,13.41,6.51,16.86,11a25.43,25.43,0,0,1,2.66,4.65l-13.42,3.85s-3.05-4.38-5.84-6.11c0,0-.13-.13-.8-.13H838a2,2,0,0,0-.8.13c-2.12,1.06-5.71,4.78-5.71,5.85v6.64a2.29,2.29,0,0,0,1.46,2.25L867.58,113c6.78,1.85,10.36,8.5,10.36,14.47v8.37a13,13,0,0,1-2.12,7.57" fill={style2} />
                        <path d="M951.12,143.43c-1.73,2.25-6.91,8-9.7,9.56a17,17,0,0,1-8.23,2.39H913a16.61,16.61,0,0,1-8.23-2.39c-2.79-1.6-8.1-7.31-9.69-9.56a13.37,13.37,0,0,1-2.13-7.57V94.29a13.37,13.37,0,0,1,2.13-7.57c1.59-2.26,6.9-8,9.69-9.56A16.61,16.61,0,0,1,913,74.77h20.19a17,17,0,0,1,8.23,2.39c2.79,1.59,8,7.3,9.7,9.56a13.44,13.44,0,0,1,2.12,7.57v41.57a13.44,13.44,0,0,1-2.12,7.57m-44.22-7.71a1.06,1.06,0,0,0,.13.54,21.2,21.2,0,0,0,5.84,5.71,1.49,1.49,0,0,0,.67.13h19.12a1.78,1.78,0,0,0,.66-.13,21.35,21.35,0,0,0,5.85-5.71,1.39,1.39,0,0,0,.13-.54v-41s0-.53-.13-.66a22.18,22.18,0,0,0-5.85-5.85,1.45,1.45,0,0,0-.66-.13H913.54s-.54,0-.67.13A22,22,0,0,0,907,94a1.78,1.78,0,0,0-.13.66Z" fill={style2} />
                        <polygon points="1011.81 154.72 984.32 101.99 984.32 154.72 970.38 154.72 970.38 75.3 985.38 75.3 1012.61 128.69 1012.61 75.3 1026.55 75.3 1026.55 154.72 1011.81 154.72" fill={style2} /></g>
                    <g id="navisphereCarrier">
                        <path d="M1292.84,51.2h14.38l29.53,45.29h.17V51.2h10.85v61H1334l-30.13-46.75h-.17v46.75h-10.85Z" fill={style2} />
                        <path d="M1382,51.2h9.39l26.26,61h-12.4l-5.69-14h-26.43l-5.51,14h-12.14Zm13.78,37.71-9.39-24.79-9.56,24.79Z" fill={style2} />
                        <path d="M1412.86,51.2h12.48L1442,97.27,1459.1,51.2h11.71l-24.63,61h-9.38Z" fill={style2} />
                        <path d="M1478,51.2h10.85v61H1478Z" fill={style2} />
                        <path d="M1532.88,64.29a10,10,0,0,0-4.6-3.66,16,16,0,0,0-6.07-1.16,15.16,15.16,0,0,0-3.66.43,9.84,9.84,0,0,0-3.19,1.38,6.92,6.92,0,0,0-3.14,6,5.6,5.6,0,0,0,2.15,4.74,21.23,21.23,0,0,0,5.34,2.84c2.12.8,4.45,1.58,7,2.32a28.58,28.58,0,0,1,7,3.1,17.39,17.39,0,0,1,5.34,5.26,15.64,15.64,0,0,1,2.15,8.69,19.08,19.08,0,0,1-1.81,8.57,17.49,17.49,0,0,1-4.86,6.07,21.14,21.14,0,0,1-7.11,3.62,29.84,29.84,0,0,1-8.52,1.2,32.07,32.07,0,0,1-10.94-1.89,21.2,21.2,0,0,1-9-6.37l8.18-7.93a13.72,13.72,0,0,0,5.21,4.7,14.54,14.54,0,0,0,10.64,1.16,11.81,11.81,0,0,0,3.44-1.55,8.16,8.16,0,0,0,2.5-2.63,7,7,0,0,0,.94-3.66,6.36,6.36,0,0,0-2.15-5.16,18.71,18.71,0,0,0-5.34-3.06c-2.12-.83-4.45-1.62-7-2.37a29,29,0,0,1-7-3,16.87,16.87,0,0,1-5.34-5.17,15.34,15.34,0,0,1-2.16-8.65,16.42,16.42,0,0,1,1.94-8.18,17.61,17.61,0,0,1,5.08-5.73,22.3,22.3,0,0,1,7.19-3.4,31.12,31.12,0,0,1,8.27-1.12,30.06,30.06,0,0,1,9.34,1.47,21.55,21.55,0,0,1,8.13,4.82Z" fill={style2} />
                        <path d="M1553.29,51.2h20.49a41.34,41.34,0,0,1,8.48.86,21,21,0,0,1,7.19,2.89,14.71,14.71,0,0,1,5,5.42,17.64,17.64,0,0,1,1.85,8.48,18.94,18.94,0,0,1-2,9.3,14.12,14.12,0,0,1-5.34,5.47,22.11,22.11,0,0,1-7.79,2.62,60.16,60.16,0,0,1-9.25.69h-7.75v25.23h-10.85Zm17.39,26.43c1.55,0,3.16,0,4.82-.17a14.8,14.8,0,0,0,4.61-1,8.23,8.23,0,0,0,3.44-2.58,7.73,7.73,0,0,0,1.34-4.83,7.64,7.64,0,0,0-1.21-4.52,8,8,0,0,0-3.1-2.58,13.07,13.07,0,0,0-4.22-1.16,41.07,41.07,0,0,0-4.47-.26h-7.75V77.63Z" fill={style2} />
                        <path d="M1606.76,51.2h10.84V75.48h28.5V51.2H1657v61H1646.1V85.3h-28.5v26.86h-10.84Z" fill={style2} />
                        <path d="M1671.42,51.2h40.38V61h-29.54V76h28v9.81h-28v16.54h31.09v9.81h-41.93Z" fill={style2} />
                        <path d="M1724.88,51.2h21.18a42.89,42.89,0,0,1,8.49.82,21.82,21.82,0,0,1,7.27,2.8,14.64,14.64,0,0,1,5.08,5.34,17.19,17.19,0,0,1,1.89,8.52,17,17,0,0,1-3.65,11.11c-2.45,3-5.93,4.79-10.47,5.42l16.19,27h-13.09l-14.12-25.83h-7.92v25.83h-10.85ZM1744.17,77q2.32,0,4.65-.21a14.31,14.31,0,0,0,4.26-1,7.2,7.2,0,0,0,3.14-2.49,7.91,7.91,0,0,0,1.21-4.7,7.46,7.46,0,0,0-1.12-4.3,6.92,6.92,0,0,0-2.93-2.45,13.24,13.24,0,0,0-4-1.08,37,37,0,0,0-4.26-.26h-9.39V77Z" fill={style2} />
                        <path d="M1780,51.2h40.38V61h-29.53V76h28v9.81h-28v16.54h31.08v9.81H1780Z" fill={style2} />
                        <path d="M1827.59,62.07a11.94,11.94,0,0,1,1-4.84,12.69,12.69,0,0,1,2.67-3.95,13,13,0,0,1,4-2.68,12.42,12.42,0,0,1,9.67,0,12.69,12.69,0,0,1,6.63,6.63,12.3,12.3,0,0,1,0,9.67,12.69,12.69,0,0,1-6.63,6.63,12.3,12.3,0,0,1-9.67,0,12.84,12.84,0,0,1-4-2.68,12.69,12.69,0,0,1-2.67-3.95A11.89,11.89,0,0,1,1827.59,62.07Zm2.21,0a10.11,10.11,0,0,0,.8,4,10.39,10.39,0,0,0,2.19,3.26,10.21,10.21,0,0,0,3.27,2.19,10.18,10.18,0,0,0,8,0,10.19,10.19,0,0,0,5.45-5.45,10.21,10.21,0,0,0,0-8,10.19,10.19,0,0,0-5.45-5.45,10.3,10.3,0,0,0-8,0,10.38,10.38,0,0,0-3.27,2.19,10.62,10.62,0,0,0-2.19,3.26A10.12,10.12,0,0,0,1829.8,62.07Zm5.45-7.2h5.49a5.53,5.53,0,0,1,3.8,1.11,4.06,4.06,0,0,1,1.22,3.18,3.78,3.78,0,0,1-1,2.81,4,4,0,0,1-2.42,1.1l3.68,6h-2.74l-3.55-5.82h-1.91v5.82h-2.61Zm2.61,6.19h1.91c.4,0,.8,0,1.21-.05a3.29,3.29,0,0,0,1.08-.25,1.76,1.76,0,0,0,.79-.6,2.19,2.19,0,0,0,0-2.21,1.76,1.76,0,0,0-.79-.6,3.29,3.29,0,0,0-1.08-.24l-1.21,0h-1.91Z" fill={style2} />
                        <path d="M1323.87,136.73a14.31,14.31,0,0,0-5-3.75,13.77,13.77,0,0,0-4.93-.94,16.46,16.46,0,0,0-6.66,1.31,15.22,15.22,0,0,0-5.12,3.62,16.32,16.32,0,0,0-3.32,5.39,18.49,18.49,0,0,0-1.17,6.63,20.41,20.41,0,0,0,1.17,7,16.59,16.59,0,0,0,3.32,5.56,15.38,15.38,0,0,0,5.12,3.68,16.14,16.14,0,0,0,6.66,1.34,12.34,12.34,0,0,0,5.6-1.37,15.36,15.36,0,0,0,5-4.39l7,5a19.07,19.07,0,0,1-7.84,6.42,24.4,24.4,0,0,1-9.84,2,27.79,27.79,0,0,1-10.08-1.77,22.57,22.57,0,0,1-13.09-12.83,26.27,26.27,0,0,1-1.88-10.11,27.43,27.43,0,0,1,1.88-10.34,23.19,23.19,0,0,1,5.19-7.94,22.8,22.8,0,0,1,7.9-5.06,27.79,27.79,0,0,1,10.08-1.77,23.11,23.11,0,0,1,8.94,1.71,20.86,20.86,0,0,1,7.66,5.79Z" fill={style2} />
                        <path d="M1353.2,125.62h7.3L1380.93,173h-9.65l-4.42-10.85h-20.55L1342,173h-9.44ZM1363.92,155l-7.3-19.29L1349.18,155Z" fill={style2} />
                        <path d="M1386.89,125.62h16.47a33.89,33.89,0,0,1,6.6.63,17,17,0,0,1,5.66,2.18,11.41,11.41,0,0,1,4,4.15,13.39,13.39,0,0,1,1.47,6.63,13.19,13.19,0,0,1-2.85,8.64,12.19,12.19,0,0,1-8.13,4.22l12.59,21h-10.18l-11-20.09h-6.17V173h-8.43Zm15,20.08c1.2,0,2.41,0,3.61-.16a10.93,10.93,0,0,0,3.32-.81,5.61,5.61,0,0,0,2.44-1.94,6.12,6.12,0,0,0,.94-3.65,5.84,5.84,0,0,0-.87-3.35,5.41,5.41,0,0,0-2.28-1.9,10.18,10.18,0,0,0-3.11-.84,27.11,27.11,0,0,0-3.32-.2h-7.3V145.7Z" fill={style2} />
                        <path d="M1429.74,125.62h16.48a33.79,33.79,0,0,1,6.59.63,17,17,0,0,1,5.66,2.18,11.36,11.36,0,0,1,4,4.15,13.39,13.39,0,0,1,1.47,6.63,13.19,13.19,0,0,1-2.85,8.64,12.22,12.22,0,0,1-8.13,4.22l12.59,21h-10.18l-11-20.09h-6.16V173h-8.44Zm15,20.08c1.2,0,2.41,0,3.61-.16a10.93,10.93,0,0,0,3.32-.81,5.61,5.61,0,0,0,2.44-1.94,6.12,6.12,0,0,0,.94-3.65,5.84,5.84,0,0,0-.87-3.35,5.41,5.41,0,0,0-2.28-1.9,10.18,10.18,0,0,0-3.11-.84,27.22,27.22,0,0,0-3.32-.2h-7.3V145.7Z" fill={style2} />
                        <path d="M1472.4,125.62h8.44V173h-8.44Z" fill={style2} />
                        <path d="M1491.89,125.62h31.41v7.63h-23V144.9h21.76v7.64h-21.76v12.85h24.17V173h-32.61Z" fill={style2} />
                        <path d="M1533.47,125.62H1550a34,34,0,0,1,6.6.63,16.89,16.89,0,0,1,5.65,2.18,11.36,11.36,0,0,1,4,4.15,13.39,13.39,0,0,1,1.47,6.63,13.19,13.19,0,0,1-2.85,8.64,12.22,12.22,0,0,1-8.13,4.22l12.59,21h-10.18l-11-20.09h-6.16V173h-8.44Zm15,20.08c1.2,0,2.41,0,3.61-.16a10.93,10.93,0,0,0,3.32-.81,5.61,5.61,0,0,0,2.44-1.94,6.12,6.12,0,0,0,.94-3.65,5.84,5.84,0,0,0-.87-3.35,5.41,5.41,0,0,0-2.28-1.9,10.18,10.18,0,0,0-3.11-.84,27.22,27.22,0,0,0-3.32-.2h-7.3V145.7Z" fill={style2} />
                    </g>
                    <rect id="divider" x="1166.11" y="37.53" width="4" height="155.07" fill={style2} />
                </g>
            </g>
        </svg>
    )
}
import { Action, AnyAction } from 'redux';
import { Container } from 'typedi';
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/withLatestFrom';


import * as a from './manage-invoices.actions';
import { getErrorMessage } from 'app/util/util';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { InterimRepository } from 'app/repositories/interim.repository';
import { fetchInvoices } from './manage-invoices.actions';

interface ManageInvoiceEpicDependencies {
  repo: InterimRepository;
}

type InvoiceEpic<T extends Action> = NavCarrierEpic<T, ManageInvoiceEpicDependencies>;

export const updateSearchCriteriaEpic: InvoiceEpic<AnyAction> = (action$, state$) =>
  action$.ofType(a.SET_INVOICE_SEARCH_SORTING, a.SET_INVOICE_SEARCH_CRITERIA, a.SET_INVOICE_SEARCH_PAGINATION)
    .withLatestFrom(state$)
    .filter(([, state]) => Boolean(Object.keys(state.invoices.search).length))
    .map(([, state]) => ({...state.invoices.search, ...state.invoices.sorting, ...state.invoices.pagination}))
    .map(fetchInvoices);

export const fetchInvoicesEpic: InvoiceEpic<AnyAction> = (action$, state$, {repo}) =>
  action$.ofType(a.FETCH_INVOICES)
    .withLatestFrom(state$)
    .map(([action, state]) => [action.criteria, state.auth.carrier.carrierCode])
    .switchMap(([criteria, carrierCode]) =>
      repo.searchInvoices(carrierCode, criteria)
        .map(a.fetchInvoicesSuccess)
        .catch(e => Observable.of(a.fetchInvoicesFailure(e)))
    );

export const fetchInvoicesSuccessEpic: InvoiceEpic<AnyAction> = action$ =>
  action$.ofType(a.FETCH_INVOICES_SUCCESS)
    .map(({results}) => a.setInvoiceSearchResults(results));

export const fetchInvoicesFailureEpic: InvoiceEpic<AnyAction> = action$ =>
  action$.ofType(a.FETCH_INVOICES_FAILURE)
    .map(({error}) => a.setInvoiceSearchError(getErrorMessage(error)));

export const manageInvoicesEpics = (action$, state$) => combineEpics(
  updateSearchCriteriaEpic,
  fetchInvoicesEpic,
  fetchInvoicesSuccessEpic,
  fetchInvoicesFailureEpic
)(action$, state$, {repo: Container.get(InterimRepository)});

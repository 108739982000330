import { useCallback } from 'react';

import { useModal } from 'app/hooks/use-modal.hook';
import { CombinedUserData, confirmTermsAndConditionsStatus, loginError } from 'features/security/auth.actions';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';
import { UserRepository } from 'app/repositories/user.repository';
import { useAjaxRequest } from 'app/hooks/ajax/use-ajax-request.hook';
import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';

interface UseOnAcknowledgeTermsHook {
  (): () => any;
}

export const useOnAcknowledgeTerms: UseOnAcknowledgeTermsHook = () => {
  const [, hideTermsModal, {userJSON, carrierDetail}] = useModal<CombinedUserData>('accept-terms');

  const repo = useRepository(UserRepository);

  const updateUserFactory = useCallback(
    () => repo.acceptTermsAndConditions(userJSON),
    [userJSON, repo]
  );

  const dispatchLoginError = useDispatcher(loginError);
  const onAcceptanceSuccess = useDispatcher(confirmTermsAndConditionsStatus);
  const onAcceptanceError = useCallback(err => { hideTermsModal(); dispatchLoginError(err); }, [hideTermsModal, dispatchLoginError]);

  return useAjaxRequest(updateUserFactory, res => onAcceptanceSuccess(res, carrierDetail), [updateUserFactory, carrierDetail], onAcceptanceError);
};

import { ActionsObservable, combineEpics } from 'redux-observable';
import { Container } from 'typedi';
import { AnyAction } from 'redux';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';

import * as a from '../load-details.actions';
import { InterimRepository } from 'app/repositories/interim.repository';
import { documentsEpic } from '../sections/documents/documents.epics';
import { containerUpdateEpic } from '../sections/container-update/container-update.epics';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { cashAdvanceOptionsEpic } from '../sections/cash-advance/redux/cash-advance-options.epics';

interface Deps {
  repo: InterimRepository;
}

type LoadDetailsEpic<OutputAction extends AnyAction> = NavCarrierEpic<OutputAction, Deps>;
type FetchLoadsResponseAction = a.FetchLoadDetailsSuccessAction | a.FetchLoadDetailsFailureAction;

export const retrieveLoadEpic: LoadDetailsEpic<FetchLoadsResponseAction> = (action$, state$, {repo}) =>
  action$.ofType<a.FetchLoadDetailsAction>(a.FETCH_LOAD_DETAILS_REQUEST_START)
    .switchMap(({id, bookType}) =>
      repo.getLoad(id, bookType)
        .map(a.fetchLoadDetailsSuccess)
        .catch((err) => ActionsObservable.of(a.fetchLoadDetailsError(err)))
    );

export const storeLoadEpic: LoadDetailsEpic<a.SetLoadDetailsAction> = action$ =>
  action$.ofType<a.FetchLoadDetailsSuccessAction>(a.FETCH_LOAD_DETAILS_SUCCESS)
    .map(({payload}) => a.setLoadDetails(payload));

export const refreshLoadEpic: LoadDetailsEpic<AnyAction> = (action$, state$) =>
  action$.ofType(a.REFRESH_LOAD_DETAILS)
    .map(() => state$.value.loadDetails.load)
    .mergeMap(load => ActionsObservable.from([
      a.setLoadDetails(null),
      a.fetchLoadDetails(load.number, load.loadBook.bookType, true)
    ]));

export const loadDetailsEpic: LoadDetailsEpic<AnyAction> = (action$, state$) =>
  combineEpics(
    retrieveLoadEpic,
    storeLoadEpic,
    refreshLoadEpic,
    documentsEpic,
    cashAdvanceOptionsEpic,
    containerUpdateEpic
  )(action$, state$, {repo: Container.get(InterimRepository)});

import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import * as a from './container-update.actions';
import { ShippingContainer } from 'shared/models/shipments/shipping-container.model';
import { ContainerStatus } from 'shared/models/shipments/container-status.model';
import { EquipmentInfo } from 'shared/models/shipments/equipment-info.model';

export const ContainerUpdateReducer = (state = [], action) => {
  switch (action.type) {
    case a.FETCH_CONTAINERS_SUCCESS:
      return {...state, containers: action.payload};
    case a.FETCH_CONTAINER_STATUSES_SUCCESS:
      return {...state, containerStatuses: SortContainerStatuses(action.payload)};
    case a.FETCH_CONTAINER_UPDATE_FAILURE:
      return {...state, error: action.error};
    case a.FETCH_EQUIPMENT_INFO_SUCCESS:
      return {...state, equipmentInfo: action.payload}
    default:
      return state;
  }
};

export interface ContainerUpdateState {
  containers: ShippingContainer[];
  containerStatuses: ContainerStatus[];
  equipmentInfo: EquipmentInfo
  error: AjaxError;
}

const SortContainerStatuses = (statuses: ContainerStatus[]) => {
  return statuses.sort((a,b) => a.progressionOrder - b.progressionOrder);
};

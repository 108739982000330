import React from 'react';
import classNames from 'classnames';
import { useCallback } from 'react';

import { Translation } from 'shared/components/translation/translation.component';
import { CultureSelectForm } from 'shared/components/culture-select/culture-select.component';
import { useMenuControls } from '../hooks/use-menu-controls.hook';
import { saveCulture } from 'app/i18n/culture.actions';
import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';
import { useSelector } from 'app/hooks/store/use-selector.hook';

import './culture-select.component.scss';

export const CultureSelect: React.FC = () => {
  const menu = useMenuControls();
  const culture = useSelector(state => state.culture);
  const dispatchSaveCulture = useDispatcher(saveCulture);

  const updateCulture = useCallback(newCulture => {
    dispatchSaveCulture(newCulture);
    menu.close();
  }, [menu.close, dispatchSaveCulture]);

  if (!culture) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="btn-group culture-select" ref={menu.ref}>
        <button id="btn-change-culture" onClick={menu.toggle} className="dropdown-toggle" aria-expanded={menu.isOpen}>
          <span className="visually-hidden"><Translation resource="SELECT_LANGUAGE_CURRENT_LANGUAGE_IS"/>{' '}</span>
          {culture.locale?.displayName} ({culture.country.code}) &nbsp;
          {menu.isOpen
            ? <span className="glyphicon glyphicon-menu-up"/>
            : <span className="glyphicon glyphicon-menu-down"/>
          }
        </button>
        <div className={classNames('dropdown-menu', {'display-block': menu.isOpen})}>
          <div className="h2"><Translation resource="SELECT_LANGUAGE"/></div>{' '}
          <CultureSelectForm onSubmit={updateCulture} onCancel={menu.close} culture={culture}/>
        </div>
      </div>
    </React.Fragment>
  );
};

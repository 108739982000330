import { AppRoute } from '@app/routesEnum';
import { MaintenanceWindow } from '@containers/layout/maintenance/maintenance-window.component';
import { Pages } from '@pages/pages';
import { UnauthenticatedRoute } from '@shared/components/route/unauthenticated-route.component';
import Animation from '@pages/login/animation/animation.component';
import { Route, Switch } from 'react-router';

export interface ILoginRoutes {
 oktaUIEnabled?: boolean;
}

const LoginRoutes = ({ oktaUIEnabled = false }: ILoginRoutes) => {
 return (
  <>
   <UnauthenticatedRoute path={AppRoute.LOGIN}>
    <MaintenanceWindow />
    <Animation>
     <Route exact path={AppRoute.LOGIN} render={() => <Pages.Login {...{ oktaUIEnabled }} />} />
     <Route path={AppRoute.FORGOT_USERNAME}>
      <Switch>
       <Route path={AppRoute.FORGOT_USERNAME} exact component={Pages.ForgotUsername} />
       <Route path={AppRoute.FORGOT_USERNAME_SUCCESS} component={Pages.ForgotUsernameSuccess} />
      </Switch>
     </Route>
     <Route path={AppRoute.FORGOT_PASSWORD}>
      <Switch>
       <Route path={AppRoute.FORGOT_PASSWORD} exact component={Pages.ForgotPassword} />
       <Route path={AppRoute.FORGOT_PASSWORD_SUCCESS} component={Pages.ForgotPasswordSuccess} />
      </Switch>
     </Route>
     <Route path={AppRoute.RESET_PASSWORD} component={Pages.ResetPassword} />
    </Animation>
   </UnauthenticatedRoute>
   <UnauthenticatedRoute path={AppRoute.LOGIN_OTP}>
    <Route path={AppRoute.LOGIN_OTP} component={Pages.LoginOtpComponent} />
   </UnauthenticatedRoute>
   <Route path={AppRoute.VERIFY_EMAIL} component={Pages.VerifyEmailAddress} />
  </>
 );
};

export default LoginRoutes;
